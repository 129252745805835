<template>
    <Header v-if="isPC"></Header>
    <MobileHeader v-if="!isPC"></MobileHeader>
    <div class="contact_container">
<!--        <div class="banner-box">
            <img class="banner" src="assets/images/about_banner.png"/>
            <div class="banner-text">
                <p>LA CHANSONNIERE</p>
                <p>蘭颂西餐厅</p>
            </div>
        </div>
        <div style="max-width: 1360px;margin: 0 auto;height: 1px;background: #aaa"></div>
        -->

        <JobOpportunities></JobOpportunities>
        <div style="max-width: 1360px;margin: 0 auto;height: 1px;background: #ccc"></div>
        <div class="address-container">
            <div class="address">
                <p>
                    <svg width="20" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.99998 1.6665C6.23187 1.6665 4.53618 2.36888 3.28593 3.61913C2.03569 4.86937 1.33331 6.56506 1.33331 8.33317C1.33331 9.90984 1.66831 10.9415 2.58331 12.0832L7.99998 18.3332L13.4166 12.0832C14.3316 10.9415 14.6666 9.90984 14.6666 8.33317C14.6666 6.56506 13.9643 4.86937 12.714 3.61913C11.4638 2.36888 9.76809 1.6665 7.99998 1.6665V1.6665Z"
                              stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>北京市 望京SOHO-T2楼 C座 0509室</span>
                </p>
                <p>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.8334 3.3335H4.16669C3.50365 3.3335 2.86776 3.59689 2.39892 4.06573C1.93008 4.53457 1.66669 5.17045 1.66669 5.8335V14.1668C1.66669 14.8299 1.93008 15.4658 2.39892 15.9346C2.86776 16.4034 3.50365 16.6668 4.16669 16.6668H15.8334C16.4964 16.6668 17.1323 16.4034 17.6011 15.9346C18.07 15.4658 18.3334 14.8299 18.3334 14.1668V5.8335C18.3334 5.17045 18.07 4.53457 17.6011 4.06573C17.1323 3.59689 16.4964 3.3335 15.8334 3.3335ZM15.275 5.00016L10 8.9585L4.72502 5.00016H15.275ZM15.8334 15.0002H4.16669C3.94567 15.0002 3.73371 14.9124 3.57743 14.7561C3.42115 14.5998 3.33335 14.3878 3.33335 14.1668V6.04183L9.50002 10.6668C9.64427 10.775 9.81971 10.8335 10 10.8335C10.1803 10.8335 10.3558 10.775 10.5 10.6668L16.6667 6.04183V14.1668C16.6667 14.3878 16.5789 14.5998 16.4226 14.7561C16.2663 14.9124 16.0544 15.0002 15.8334 15.0002Z"
                              fill="#666666"/>
                    </svg>
                    <span>hr@gbspace.com</span>
                </p>
                <p>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.79497 5.20019L5.9758 1.94602C5.6508 1.57102 5.05497 1.57269 4.67747 1.95102L2.35914 4.27352C1.66914 4.96435 1.47164 5.99019 1.8708 6.81269C4.25548 11.7502 8.23751 15.7378 13.1716 18.1294C13.9933 18.5285 15.0183 18.331 15.7083 17.6402L18.0483 15.296C18.4275 14.9169 18.4283 14.3177 18.05 13.9927L14.7833 11.1885C14.4416 10.8952 13.9108 10.9335 13.5683 11.2769L12.4316 12.4152C12.3734 12.4762 12.2968 12.5164 12.2136 12.5296C12.1303 12.5429 12.0451 12.5284 11.9708 12.4885C10.1128 11.4186 8.57163 9.87538 7.50414 8.01602C7.46416 7.94165 7.44968 7.85621 7.46293 7.77282C7.47618 7.68942 7.51641 7.61268 7.57747 7.55435L8.7108 6.42019C9.05414 6.07519 9.09164 5.54185 8.79497 5.19935V5.20019Z"
                              stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>+86 18612627327</span>
                </p>
            </div>
        </div>

    </div>
    <div id="map"></div>
    <Footer style="margin-top: 0px"></Footer>
</template>

<script>
    import Header from "@/components/common/Header";
    import Footer from "@/components/index/Footer";
    import MobileHeader from "@/components/common/MobileHeader";
    import mapboxgl, {Map, Marker} from "mapbox-gl";
    import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
    import JobOpportunities from "@/components/contact/JobOpportunities";

    export default {
        name: "Contact1",
        components: {Header, Footer, MobileHeader, JobOpportunities},
        computed: {
            isPC: function () {
                var userAgentInfo = navigator.userAgent;
                console.log(userAgentInfo);
                var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod"];
                var flag = true;
                for (var v = 0; v < Agents.length; v++) {
                    if (userAgentInfo.indexOf(Agents[v]) > 0) {
                        flag = false;
                        break;
                    }
                }
                console.log(window.innerWidth);
                if (flag && window.innerWidth < 600) {
                    flag = false
                }
                /* if (window.screen.width >= 768) {
                     flag = true;
                 }*/
                return flag;
            }
        },
        mounted() {

            mapboxgl.accessToken = "pk.eyJ1IjoiZ2Itc3BhY2UiLCJhIjoiY2w0ejlnejl2MXkyaDNjbXptbjQ1dzZrMCJ9.JaCZgVdp_8_6Bg7DHQDGIA";
            const map = new mapboxgl.Map({
                "container": "map",
                "style": "mapbox://styles/mapbox/streets-v10",
                "center": [116.47379832819, 39.996829268285],
                "zoom": 15.384354831539,
                "pitch": 21.5,
                "bearing": 0
            });
            map.addControl(
                new MapboxGeocoder({
                    accessToken: mapboxgl.accessToken,
                    mapboxgl: mapboxgl
                })
                , "top-left"
            );
            const popup1 = new mapboxgl.Popup({offset: [-0.5, -10], focusAfterOpen: false})
                .setLngLat([116.47385394448736, 39.99618372688286])
                .setHTML("<p style='margin-top: 10px'>Wave.GB</p>");

            const marker = new mapboxgl.Marker({
                color: "black",
                anchor: "top",
                scale: 0.5
            }).setLngLat([116.47385394448736, 39.99618372688286])
                .setPopup(popup1)
                .addTo(map);
            marker.getPopup().addTo(map);
            const nav = new mapboxgl.NavigationControl({
                visualizePitch: true
            });
            map.addControl(nav, "top-right");

        }
    }
</script>

<style scoped lang="scss">
    @import "../components/common/common.scss";

    .banner-box {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 20px;
        max-width: 1360px;

        .banner {
            width: 100%;
            margin-top: 20px;

        }

        .banner-text {
            margin-top: 10px;
            font-family: $font-family;
            font-style: $font-style;
            font-weight: $font-weight-normal;
            font-size: 16px;
            line-height: 19px;
            text-align: right;
        }
    }

    @media only screen and (max-width: 940px) {
        .address-container {
            padding: 0 20px;
        }
    }

    @media only screen and (max-width: 430px) {
        .address span {
            max-width: 300px;
            max-width: calc(100vw - 60px - 52px);
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    #map {
        height: 360px;
        width: 100%;
    }

    .address-container {
        max-width: 940px;
        margin: 0 auto;
        margin-top: 40px;
        margin-bottom: 80px;
    }

    .address {

        p {
            text-align: left;
            overflow: hidden;
            margin-bottom: 18px;

            svg {
                margin-right: 16px;
                float: left;
            }

            span {
                font-weight: 200;
                font-size: 14px;
                line-height: 20px;
                float: left;
            }
        }

        p:last-child {
            margin-bottom: 0;
        }
    }
</style>
