<template>
    <div class="slogn-container">
        <div class="slogn-box">
            <div class="logo"></div>
            <div class="slogn-text">
                <p>专业的系统化设计服务</p>
                <p>Professional Systematic Design</p>
            </div>
        </div>
        <div class="about-text">
            <p>Wave. GB
                2015年创立于北京，是一家具有创新理念的设计服务公司。我们强调设计的独特性、系统性和整体性，在合作伙伴的营销链路中，找到对的体验感受需求，用设计来优化用户与品牌、用户与产品之间的创新体验方式，以适合的设计场景与消费者建立真实的情感链接，用设计的力量提升体验感和销售。我们涵盖品牌策划，视觉设计，空间设计，新媒体设计等服务内容，我们希望通过专业联动为客户提供系统化设计服务。</p>
            <br>
            <p>Founded in Beijing in 2015, Wave.GB is a design service company with innovative ideas. We emphasize the uniqueness, systemic and holistic nature of design, looking for the right demand
                of experience in the marketing chain of our partners, using design to optimize the innovative experience between users and brands, as well as users and products, establishing real
                emotional links with consumers with suitable design scenarios, as well as using the power of design to enhance the experience and sales. Our services cover brand planning, visual
                design, space design, new media design etc., and we hope to provide systematic design services for our clients with professional linkage.</p>
        </div>
    </div>

</template>

<script>
    export default {
        name: "Slogn"
    }
</script>

<style scoped lang="scss">
    @import "../../components/common/common.scss";

    @media only screen and (max-width: 1360px) {
        .slogn-container {
            padding-left: 40px;
            padding-right: 40px;
        }
    }
    @media only screen and (min-width: 900px) and (max-width: 1100px) {
        .slogn-container {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    @media only screen and (max-width: 900px) {
        .slogn-container {
            padding-left: 24px !important;
            padding-right: 24px !important;
        }
        .slogn-box{
            transform: scale(0.8);
        }
    }

    @media only screen and (max-width: 440px) {
        .slogn-box {
            flex-direction: column;
            align-items: center;

            .slogn-text {
                margin-left: 0 !important;
                margin-top: 20px !important;
                width: 100%;
                text-align: center !important;
            }
        }
    }
    @media only screen and (max-width: 450px) {
        .slogn-box{
            padding-bottom: 60px !important;
        }
        .about-text {
            font-size: 14px !important;
        }
    }
    .slogn-container {

    }

    .slogn-box {
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 80px;
        padding-bottom: 100px;

        .slogn-text {
            float: left;
            font-family: $font-family;
            font-style: $font-style;
            font-weight: $font-weight-border;
            font-size: $font-size-16;
            line-height: $line-height-16;
            color: #333;
            text-align: left;
            align-self: flex-end;
            margin-left: 40px;
        }
    }

    .about-text {
        font-family: $font-family;
        font-style: $font-style;
        font-weight: $font-weight-normal;
        font-size: $font-size-16;
        line-height: 22px;
        color: #666;
        text-align: left;
        margin: 64px 0;
    }
</style>