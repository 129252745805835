<template>
  <Header v-if="isPC"></Header>
  <MobileHeader v-if="!isPC"></MobileHeader>
  <div class="media_container">
    <div class="banner-box">
      <img class="banner" src="assets/images/media_banner1.png"/>
<!--      <div class="banner-text">-->
<!--        <p>LA CHANSONNIERE</p>-->
<!--        <p>蘭颂西餐厅</p>-->
<!--      </div>-->
    </div>
    <Awards :awards="media.awards"></Awards>
    <Reports :reports="media.reports"></Reports>
  </div>
  <Footer style="margin-top: 100px"></Footer>
</template>

<script>
import Header from '@/components/common/Header';
import Footer from '@/components/index/Footer';
import MobileHeader from '@/components/common/MobileHeader';
import Awards from '@/components/media/Awards.vue';
import Reports from '@/components/media/Reports';
import axios from 'axios';

export default {
  name: 'Media',
  components: {
    Header,
    Footer,
    Awards,
    MobileHeader,
    Reports
  },
  computed: {
    isPC: function () {
      var userAgentInfo = navigator.userAgent;
      console.log(userAgentInfo);
      var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPod'];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      console.log(window.innerWidth);
      if (flag && window.innerWidth < 600) {
        flag = false;
      }
      /* if (window.screen.width >= 768) {
           flag = true;
       }*/
      return flag;
    }
  },
  data() {
    return {
      media: {
        awards: [],
        reports: []
      }
    };
  },
  async created() {
    let res = await axios.get('assets/db/media.json');
    console.log(res.data);
    this.media = res.data;
  }
};
</script>

<style scoped lang="scss">
@import "../components/common/common.scss";

.banner-box {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 1360px;

  .banner {
    width: 100%;
    margin-top: 20px;

  }

  .banner-text {
    margin-top: 10px;
    font-family: $font-family;
    font-style: $font-style;
    font-weight: $font-weight-normal;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #666666;
  }
}
</style>