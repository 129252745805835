<template>
    <div class="our-clients-container">
        <div class="our-clients-title">
            <p>我们的客户</p>
            <p>Our clients</p>
        </div>
        <div class="our-clients-content">
            <div class="our-clients">
                <!--<div class="our-clients-title">
                    <p>我们的客户</p>
                    <p>Our clients</p>
                </div>-->
                <div class="our-clients-desc">
                    <p>北京国际设计周、北京国际摄影周、威尼斯建筑双年展、新华社、中信出版集团、北京师范大学瀚德学院、北大附中、苏州吴宫酒店、FLC 星地中心、新世界地产、北京泛太平洋酒店、共享际、开心麻花、凌致时装、凯风资本等品牌。</p>
                    <br>
                    <p>Beijing Design Week, Photobeijing, Venice Biennale Architecture, Xinhua News Agency, CITIC Press Group, Beijing Normal University Maxdo College, The Affiliated High School of
                        Peking University, Pan Pacific Suzhou, Fairy Land Center, New World China Land Limited, Pan Pacific Beijing, 5Lmeet, Mahua FunAge, Bestseller, Co-win Ventures etc.</p>
                </div>
            </div>
            <img class="our-clients-bg" src="assets/images/our_clients.png" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        name: "Clients"
    }
</script>


<style scoped lang="scss">
    @import "../../components/common/common.scss";

    @media only screen and (max-width: 1360px) {
        .our-clients-container {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    @media only screen and (min-width: 1150px) and (max-width: 1360px) {
        .our-clients-bg {
            margin-left: 60px !important;
        }
    }

    @media only screen and (min-width: 1100px) and (max-width: 1150px) {
        .our-clients-bg {
            margin-left: 40px !important;
        }
    }

    @media only screen and (min-width: 1050px) and (max-width: 1100px) {
        .our-clients-container {
            padding-left: 20px !important;
            padding-right: 20px !important;
        }
        .our-clients-bg {
            margin-left: 20px !important;
        }
    }

    @media only screen and (max-width: 1050px) {
        .our-clients-container {
            padding-left: 20px !important;
            padding-right: 20px !important;
        }
        .our-clients-content{
         display: block !important;
        }
        .our-clients{
            width: 100%;
        }
        .our-clients-bg{
            width: 100% !important;
            height: auto !important;
            margin-top: 20px !important;
            margin-left: 0 !important;
        }
    }

    @media only screen and (max-width: 900px) {
        .our-clients-container {
            padding-left: 24px !important;
            padding-right: 24px !important;
        }
    }

    @media only screen and (max-width: 450px) {
        .our-clients-desc {
            font-size: 14px !important;
        }
    }
    .our-clients-container {
        padding-top: 80px;
        padding-bottom: 100px;
        text-align: left;
        .our-clients-title {
            font-family: $font-family;
            font-style: $font-style;
            font-weight: $font-weight-border;
            font-size: $font-size-20;
            line-height: $line-height-20;
            color: #333333;
        }
        .our-clients-content{
            display: flex;
            .our-clients {
                text-align: left;
                .our-clients-desc {
                    font-family: $font-family;
                    font-style: $font-style;
                    font-weight: $font-weight-normal;
                    font-size: $font-size-16;
                    line-height: 22px;
                    margin-top: 20px;
                    color: #666666;
                }
            }

            .our-clients-bg {
                width: 600px;
                height: 311px;
                margin-left: 82px;
                margin-top: -46px;
            }
        }

    }
</style>