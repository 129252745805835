<template>
    <div style="width: 100%">
        <div class="header" :class="{'white-header':navBarColor === 'white'}">
            <div class="logo" @click="routerlink('/')">
                <transition name="fade">
                    <div class="whiteLogo" v-show="navBarColor === 'white'"></div>
                </transition>
            </div>
            <div class="menu-btn" @click="menu_btn_on_click">
                <svg class="_2YXEv" :class="{close:!btnType}" :preserveAspectRatio="btnType?'none':''" viewBox="0 0 17 17">
                    <line class="IB4J- _1ht-N" x2="100%"></line>
                    <line class="_1-3Af _1ht-N" x2="100%"></line>
                    <line class="_2hFLY _1ht-N" x2="100%"></line>
                </svg>
            </div>
            <div class="nav-container" :class="{active:btnType}">
                <div class="nav-box">
                    <div class="nav" @click="routerlink('/work/space/all')">空间设计</div>
                    <div class="nav" @click="routerlink('/work/graphic')">平面设计</div>
                    <div  @click="routerlink('/media')" class="nav">媒体</div>
                    <div @click="routerlink('/about')" class="nav">关于我们</div>
                    <div @click="routerlink('/contact')" class="nav">联系我们</div>
                </div>
                <!--            <div class="nav">EN</div>-->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MobileHeader",
        props: ["navBarColor"],
        data() {
            return {
                btnType: true
            }
        },
        methods: {
            routerlink(path){
                this.$emit("chooseNav");
                this.btnType = true;
                this.$router.push(path)
            },
            menu_btn_on_click() {
                this.btnType = !this.btnType
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../components/common/common.scss";
    @media only screen and (max-width: 1360px) {
        ._2YXEv,.header {
            padding-left: 40px !important;
            padding-right: 40px !important;
          width: calc(100% - 80px) !important;
        }
    }

    @media only screen and (min-width: 900px) and (max-width: 1100px) {
        ._2YXEv,.header {
            padding-left: 20px !important;
            padding-right: 20px !important;
        }

    }

    @media only screen and (max-width: 900px) {
        ._2YXEv,.header {
            padding-left: 24px !important;
            padding-right: 24px !important;
        }

    }
    .index-header .header{
        z-index: 20;
        position: absolute;
        width: calc(100% - 48px);
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0)
    }

    .white-header {
        ._2YXEv {
            stroke: rgb(255, 255, 255);
        }
    }

    .header {
        padding: 24px;
        padding-bottom: 0;
        background: white;
        margin: 0 auto;
        font-family: $font-family;
        font-style: $font-style;
        font-weight: $font-weight-border;
        font-size: $font-size-18;
        line-height: 24px;
        /*border-bottom: solid 1px #aaa;*/
        overflow: hidden;

        .fade-enter-active, .fade-leave-active {
            transition: opacity 0.5s;
        }

        .fade-enter, .fade-leave-to {
            opacity: 0;
        }

        .whiteLogo {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: url("../../assets/images/white_logo.jpg");
            background-size: 100% 100%;
        }

        .logo {
            width: 64px;
            height: 64px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            position: relative;
        }

        .menu-btn {
            position: absolute;
            right: 0;
            z-index: 3;
        }

        .nav-container {
            position: fixed;
            z-index: 2;
            width: 100%;
            height: 100%;
            left: 0;
            transition: all 300ms;
            top: 0;
            background: black;

            .nav-box {
                position: absolute;
                right: 6.154vw;
                top: 50%;
                transform: translateY(-50%);
            }

            .nav {
                font-weight: 700;
                /*font-size: 2.37vh;
                line-height: 5.687vh;*/
                font-size: 6.1538vw;
                line-height: 12.307vw;
                color: #FFFFFF;
                text-align: right;
            }
        }

        .nav-container.active {
            left: 102vw;
        }
    }

    @keyframes _20fUk {
        100% {
            transform: translateY(8.5px);
        }
        0% {
            transform: translateY(8.5px);
        }
    }

    @keyframes _3lr92 {
        100% {
            transform: translateY(0.5px);
        }
        50% {
            transform: translateY(8.5px);
        }
        0% {
            transform: translateY(8.5px) rotate(45deg);
        }
    }

    @keyframes _1kjId {
        100% {
            transform: translateY(16.5px);
        }
        50% {
            transform: translateY(8.5px);
        }
        0% {
            transform: translateY(8.5px) rotate(-45deg);
        }
    }

    @keyframes _2TnVD {
        0% {
            transform: translateY(0.5px);
        }
        50% {
            transform: translateY(8.5px);
        }
        100% {
            transform: translateY(8.5px) rotate(45deg);
        }
    }

    @keyframes _3GGVx {
        0% {
            transform: translateY(16.5px);
        }
        50% {
            transform: translateY(8.5px);
        }
        100% {
            transform: translateY(8.5px) rotate(-45deg);
        }
    }

    @keyframes _2w9-f {
        0% {
            transform: translateY(8.5px);
        }

        100% {
            transform: translateY(8.5px) rotateX(90deg);
        }
    }

    ._2YXEv {
        stroke: rgb(0, 0, 0);
        transition-duration: .3s;
        transition-property: stroke;
        stroke-linecap: round;
        stroke-width: 2px;
        /*width: 20px;*/
        /*height: 17px;*/
        max-width: 30px;
        max-height: 25.5px;
        width: 5.128vw;
        height: 4.359vw;
        overflow: visible;
        padding: 20.5px;

        .IB4J- {
            transform-origin: 8.5px 0;
            /*animation-timing-function: ease-in;*/
            animation-duration: .15s;
            animation-fill-mode: both;
            /*animation-iteration-count:infinite;*/
            animation-name: _3lr92;
        }

        ._1-3Af {
            transform: translateY(8.5px);
            transform-origin: 8.5px 0;
            animation-fill-mode: both;
            /*animation-timing-function: ease-in;*/
            animation-duration: .15s;
            /*animation-iteration-count:infinite;*/
            animation-name: _1kjId;
        }

        ._2hFLY {
            transform: translateY(16.5px);
            animation-name: _20fUk;
            animation-fill-mode: both;
            transform-origin: 8.5px 0;
            /*animation-timing-function: ease-in;*/
            animation-duration: .15s;
            /*animation-iteration-count:infinite;*/
            animation-name: _20fUk;
        }
    }

    ._2YXEv.close {
        stroke: rgb(255, 255, 255);

        .IB4J- {
            animation-name: _2TnVD;
        }

        ._1-3Af {
            animation-name: _3GGVx;
        }

        ._2hFLY {
            animation-name: _2w9-f;
        }
    }
</style>