<template>
  <div ref="test" class="about box_wrap ">
    <img class="bg"  src="assets/images/about.png"/>
    <img class="bg" :class="{active:textImg}" src="assets/images/about-sec.png" alt="">
  </div>
</template>

<script>
export default {
  name: 'About',
  data(){
    return{
      textImg:false
    }
  },
  mounted() {
this.showText()
  },
  methods: {
    showText() {
      if (!this.isShowText()){
        window.addEventListener('scroll', this.isShowText);
      }

    },
    isShowText(){
      let testdom = this.$refs.test;
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      console.log(scrollTop,testdom.offsetTop , document.body.offsetHeight / 2 , testdom.offsetHeight / 2);
      if (scrollTop > testdom.offsetTop - document.body.offsetHeight / 2 + testdom.offsetHeight / 2){
        console.log("1231231231")
        window.removeEventListener('scroll',this.isShowText)
        this.textImg = true
      }
      return scrollTop > testdom.offsetTop - document.body.offsetHeight / 2 + testdom.offsetHeight / 2
    }
  }
};
</script>

<style scoped lang="scss">
@media only screen and (max-width: 1360px) {
  .about {
    padding: 0 40px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1100px) {
  .about {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 800px) {
  .about {
    display: block !important;
  }
  .bg {
    width: calc(100vw - 48px) !important;
  }
  .bg:last-child {
    opacity: 0;
    transition: opacity 0.5s;
    position: absolute;
    z-index: 2;
    top: 0;
    width: 100%;
    margin-top: 0;
  }
  .bg.active{
    opacity: 1;
  }
}

@media only screen and (max-width: 500px) {
  .bg:last-child {

  }
}

@media only screen and (max-width: 900px) {
  .about {
    padding: 0 24px;
  }
}

.about {
  margin-top: 100px !important;
  display: flex;
  justify-content: space-between;
  position: relative;
  //width: 100%;

  .bg {
    width: 49.66%;
  }

}
</style>