<template>
  <div class="work">
    <div class="card" v-for="item in works" @click="this.$router.push(`/detail/${item.id}/${item.title}`)">
      <div class="card-bg-container">
        <ImgPlus class="card-bg" :src="item.poster" :parentRatio="3 / 2"></ImgPlus>
      </div>
      <div class="card-mask"></div>
      <div class="card-desc-box">
        <div class="card-desc-title">{{ item.title }}</div>
        <div class="card-desc">{{ item.subTitle }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ImgPlus from "@/components/common/ImgPlus";

export default {
  name: "Work",
  components: {ImgPlus},
  data() {
    return {
      works: [],
    }
  },
  async created() {
    let res = await axios.get('/assets/db/workList.json');
    let works = res.data;
    let workList = [];
    console.log(works);
    works.map((work) => {
      if (work.homeRecommended && work.homeRecommended > 0) {
        workList.push(work)
      }
    })
    workList.sort((a, b) => {
      return b.homeRecommended - a.homeRecommended
    })
    this.works = workList
  }
}
</script>

<style scoped lang="scss">
@media only screen and (max-width: 1360px) {
  .work {
    padding: 0 40px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1100px) {
  .work {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 900px) {
  .work {
    padding: 0 24px;
  }
}

@media only screen and (min-width: 700px) and (max-width: 800px) {
  .card {
    width: 50% !important;
  }
}

@media only screen and (max-width: 700px) {
  .card {
    width: 100% !important;
    margin-bottom: 40px;

    .card-desc-box {
      position: unset !important;

      opacity: 1 !important;
      margin-top: 6px !important;

      .card-desc-title {
        line-height: 29px !important;
        font-weight: 700 !important;
        color: #333 !important;
        margin-bottom: 0 !important;
      }

      .card-desc {
        font-size: 16px !important;
        color: #666 !important;
        margin-top: 0 !important;
      }
    }
  }
  .card:hover {
    .card-mask {
      display: none;
    }

    .card-bg {
      transform: scale(1) !important;
    }
  }
}

.work {
  margin: 0 auto;
  margin-top: 100px;
  max-width: 1360px;
  position: relative;
  overflow: hidden;

  .card {
    width: 33.33%;
    float: left;
    overflow: hidden;
    cursor: pointer;
    position: relative;

    .card-bg-container {
      width: 100%;
      position: relative;
      padding-bottom: calc(100% * 2 / 3);
    }

    .card-bg {
      width: 100%;
      transform: scale(1);
      transition: all .5s;
      position: absolute;
    }

    .card-mask {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      background: rgba(0, 0, 0, 0);
      transition: all .5s;
      pointer-events: none;
    }

    .card-desc-box {
      position: absolute;
      pointer-events: none;
      top: 0;
      width: 100%;
      height: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: white;
      opacity: 0;
      transition: all .5s;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      z-index: 4;


      .card-desc-title {
        font-size: 18px;
        margin: 3px 0;
      }

      .card-desc {
        margin: 3px 0;
      }

    }
  }

  .card:hover {

    .card-bg {
      transform: scale(1.1)
    }

    .card-mask {
      background: rgba(0, 0, 0, 0.7);
    }

    .card-desc-box {
      opacity: 1;
    }

  }
}
</style>