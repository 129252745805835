<template>
  <div class="reports">
    <div class="title">
      <p>媒体报道</p>
      <p>Press</p>
    </div>
    <div class="content" v-if="reports.length === 4">

      <a class="card" href="https://interiordesign.net/projects/10-international-coffee-shops/">
        <img src="assets/images/interiordesign_logo.svg" alt="">
      </a>
      <a class="card" href="https://www.archdaily.cn/cn/800410/fei-li-pu-ka-pei-yi-shi-ka-pei-fen-wei-xian-dai-quan-shi-gb-space">
        <img class="long-img" src="assets/images/archdaily_logo.svg" alt="">
      </a>
      <a class="card" href="https://www.frameweb.com/article/gb-space-juxtaposes-technology-and-nostalgia-at-a-chinese-cafe">
        <img src="assets/images/frameweb_logo.svg" alt="">
      </a>
      <a class="card" href="https://www.gooood.cn/5lmeet-co-living-community-china-by-gb-space.htm">
        <img src="assets/images/good_logo.jpg" alt="">
      </a>
<!--      <a class="card" href="http://www.timeoutbeijing.com/">
        <img src="http://www.timeoutbeijing.com/images/timeout.png" alt="">
      </a>-->
      <!--      <div class="report-card" v-for="(item,index) in reports">
              <div class="report-logo-box">
                <ImgPlus class="report-logo" :src="item.icon" :parentRatio="1"></ImgPlus>
                &lt;!&ndash;          <img class="report-logo" src="assets/images/report_test1.png"/>&ndash;&gt;
              </div>
              <div class="report-content">
                <div class="report-title">{{ item.title }}</div>
                <div class="report-desc" v-html="item.desc">
                </div>
              </div>
            </div>-->
    </div>
  </div>
</template>

<script>
import ImgPlus from '@/components/common/ImgPlus';

export default {
  name: 'Reports',
  props: ['reports'],
  components: {ImgPlus},
};
</script>

<style scoped lang="scss">
@import "../../components/common/common.scss";

@media only screen and (min-width: 900px) and (max-width: 1100px) {
  .reports {
    padding: 20px !important;
  }
}

@media only screen and (max-width: 900px) {
  .content{
    margin-top: 40px;
    margin-bottom: -40px;
  }
  .report-card {
    width: 100% !important;
    margin-right: 0 !important;
  }
  .reports .content .card{
    width: 50% !important;
    padding-bottom: 35% !important;
    .long-img{
      width: 30% !important;
    }
  }
}

@media only screen and (min-width: 650px) and (max-width: 900px) {
  .reports {
    padding: 40px !important;
  }

}

@media only screen and (max-width: 650px) {
  .reports {
    padding: 24px !important;

    .report-card {
      flex-direction: column;
      align-items: center;

      .report-logo-box {
        margin-bottom: 20px;
      }

      .report-content {
        width: 100% !important;

        .report-title {
          text-align: center;
          width: 100% !important;
          margin-left: 0 !important;
        }

      }
    }
  }
}

@media only screen and (max-width: 450px) {
}

.reports {
  max-width: 1360px;
  margin: 0 auto;
  padding: 40px;
  padding-bottom: 0px;

  .title {
    text-align: left;
    font-family: $font-family;
    font-style: $font-style;
    font-weight: $font-weight-border;
    font-size: $font-size-20;
    line-height: $line-height-20;
    margin-bottom: 30px;
    color: #333;
  }

  .content {
    width: 100%;
    overflow: hidden;

    .card {
      width: 25%;
      float: left;
      height: 0;
      padding-bottom: 30%;
      position: relative;
      transition:transform .4s;
      img{
        position: absolute;
        width: 60%;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
      .long-img{
        width: 40%
      }
    }
    .card:hover{
      transform: scale(1.1);
    }

    .report-card {
      float: left;
      width: calc(50% - 20px);
      margin-bottom: 43px;
      display: flex;

      .report-logo-box {
        display: flex;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        height: 160px;
        width: 160px;
        flex-shrink: 0;
        margin-right: 25px;

        .report-logo {
          width: 160px;
        }
      }


      .report-content {
        width: calc(100% - 175px);

        .report-title {
          width: 226px;
          height: 23px;
          font-weight: 700;
          font-size: 20px;
          line-height: 23px;
          color: #333333;
        }

        .report-desc {
          margin-top: 20px;
          font-family: 'Times New Roman';
          font-style: italic;
          font-weight: 400;
          font-size: 16px;
          line-height: 18px;
          text-align: left;
          color: #666;
        }

      }
    }

    .report-card:nth-child(2n+1) {
      margin-right: 40px;
    }
  }
}
</style>