
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  toRefs,
} from "vue";
import router from "@/router";
import {useRoute} from "vue-router";
import Work from "@/components/index/Work.vue";
import Footer from "@/components/index/Footer.vue";
import About from "@/components/index/About.vue";
import Header from "@/components/common/Header.vue";
import MobileHeader from "@/components/common/MobileHeader.vue";
import ImgPlus from "@/components/common/ImgPlus.vue";

export default defineComponent({
  name: "Index",
  components: {Work, Footer, About, Header, MobileHeader, ImgPlus},
  setup(props, context) {
    const vm = reactive({
      realIndex: 0,
      navBarColorEnum: {
        0: "white",
        1: "white",
        2: "black",
        3: "white",
        4: "black",
        5: "white"
      } as { [index: number]: string },
      navBarColor: computed(() => {
        let realIndex: number = vm.realIndex;
        let navBarColorEnum: { [index: number]: string } = vm.navBarColorEnum;
        return navBarColorEnum[realIndex];
      }),
      isPc: computed(() => {
        var userAgentInfo = navigator.userAgent;
        console.log(userAgentInfo);
        var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod"];
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
          if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false;
            break;
          }
        }
        console.log(window.innerWidth);
        if (flag && window.innerWidth < 600) {
          flag = false;
        }
        return flag;
      }),
      isready: false,
      banners: [
        {
          "id": 45,
          "title": "STAGE ONE 露台酒吧 | STAGE ONE Terrace Bar",
          "subTitle": "戏剧结构到空间的转译 | Translation of dramatic structure to space",
          banner: {
            "all": {
              "fillMode": "autoFullScreen",
              "pic": {
                "ratio": 1.5,
                "src": "assets/works/interior/StageOneTerraceBar/02吧台水池Bar and water area.webp"
              }
            }
          }
        },
        {
          "id": 39,
          "title": "炭索餐厅 Bon Boeuf",
          "subTitle": "食与材  |  Food and Material",
          banner: {
            "all": {
              "fillMode": "autoFullScreen",
              "pic": {
                "ratio": 1.333,
                "src": "/assets/works/interior/2024BonBoeuf/01EntranceFacade.webp"
              }
            }
          }
        },
        {
          "id": 4,
          "title": "蘭颂餐厅",
          "subTitle": "味蕾荡漾的诗意空间",
          banner: {
            "all": {
              "fillMode": "autoFullScreen",
              "video": {
                "ratio": 1.777777778,
                "poster": "assets/works/interior/2019Lansong/VidLandscape.webp",
                "src": "assets/works/interior/2019Lansong/VidLandscape.mp4"
              }
            }
          }

        },
        {
          "id": 2,
          "title": "Sibilla 意大利禧彼楽起泡酒",
          "subTitle": "禧 彼 楽",
          banner: {
            "all": {
              "fillMode": "autoFullScreen",
              "pic": {
                "ratio": 1.6,
                "src": "assets/works/vi/2021sibbila/Screenshot1.webp"
              }
            }
          }
        },
        {
          "id": 3,
          "title": "苏州吴宫酒店",
          "subTitle": "苏州之窗",
          banner: {
            "all": {
              "fillMode": "autoFullScreen",
              "video": {
                "ratio": 1.739130435,
                "poster": "assets/works/vi/2021SuzhouWugong/video.png",
                "src": "assets/works/vi/2021SuzhouWugong/video.mp4"
              }
            }
          }

        },
        {
          "id": 1,
          "title": "2019 北京国际设计周",
          "subTitle": "",
          banner: {
            "all": {
              "fillMode": "autoFullScreen",
              "pic": {
                "ratio": 1.5,
                "src": "assets/works/vi/2019BeijingDesignWeek/DSC01344.webp"
              }
            }
          }
        }
      ],
      ImgPlus_0: null,
      ImgPlus_1: null,
      ImgPlus_2: null,
      ImgPlus_3: null,
      bannerNext() {
        if (vm.realIndex >= vm.banners.length - 1) {
          vm.realIndex = 0
        } else {
          vm.realIndex++
        }
      },
      bannerPrev() {
        if (vm.realIndex <= 0) {
          vm.realIndex = vm.banners.length - 1
        } else {
          vm.realIndex--
        }
      }
    });
    const refData = toRefs(vm);

    onMounted(() => {
      /*var abc = new (window as any).Swiper(".mySwiper", {
        spaceBetween: 30,
        allowTouchMove: false,
        speed: 1000,
        loop: true,

        effect: "fade",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          slideChangeTransitionEnd: function (s: any) {

          },
          slideChangeTransitionStart: function (s: any) {
            if (typeof s.realIndex !== "undefined") {
              vm.realIndex = s.realIndex;
              console.log('ImgPlus_' + s.realIndex);
              // (vm as any)['ImgPlus_' + s.realIndex][0].play()
            }
          },
        },
      });*/
    });
    return {
      ...refData,
    };

  }
});
