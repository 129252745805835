<template>
  <router-view/>
</template>

<style lang="scss">
body, html, p {
  margin: 0;
  padding: 0;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

}
body, html{
  height: 100%;
}
img {
  display: block;
  user-select: none;
  -webkit-user-drag: none;
}

#app {
  font-style: normal;
  font-family: 'Roboto', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  height: 100%;
}

@font-face {
  font-family: 'roboto';
  src: url('./assets/font/Roboto-Regular.woff2') format('woff')
}

.logo {
  //background: url(./assets/images/black_logo_1.png);
  background-size: 100% 100%;
  width: 100px;
  height: 100px;
  float: left;
}

div, p, dl, dd, a, b, u, em, input, textarea, button, select, form, section, label, span, td, li, nav, footer, body, ul, i, h1, h2, h3, h4, h5, h6 {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding: 0;
  margin: 0;
  list-style: none;
  outline: 0;
}

video::-webkit-media-controls {
  display: none !important;
}

video::-webkit-media-controls-enclosure {
  display: none !important;
}

::-webkit-media-controls {
  display: none
}

.mapboxgl-popup-close-button {
  right: 4px;
}
</style>
