<template>
  <div class="pagination-container">
    <div>
      <p>我们始终坚定空间设计是解决问题与空间体验并重的理念。</p>
      <br>
      <p> 我们致力于为委托方提供多元化的解决方案，寻找新的空间使用模式与可能性，辅助委托方打造品牌形象，我们为委托方提供《设计+项目协调+媒体推广》的系列服务。</p>
      <br>
      <p> 我们尝试突破传统空间设计的限定界限，利用材料与多种媒介寻找新的方式营造空间氛围，增强空间的互动与体验，期望通过设计让空间化作语言来传播信息。</p>
    </div>
    <CPagination v-if="this.$route.params.navGroup !== 'graphic' && workCount>0" :pageSize="pageSize" :total="workCount"
                 :navList="navList" :pager-count="4"></CPagination>
  </div>
</template>

<script>
import {ElPagination} from 'element-plus';
import CPagination from '@/components/work/CPagination';
// import "element-plus/dist/index.css";
export default {
  name: 'Pagination',
  props: ['workCount', 'pageSize', 'navList'],
  components: {
    ElPagination,
    CPagination
  },
};
</script>

<style scoped lang="scss">
@import "../../components/common/common.scss";

@media only screen and (max-width: 1360px) {
  .pagination-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 950px) {
  .pagination-container {
    flex-direction: column-reverse;

    .pagination-wrapper {
      margin-left: 0 !important;
      align-self: center !important;
      margin-bottom: 40px;

    }
  }
  ul li {
    background: red !important;
    font-size: 24px !important;
    line-height: 36px !important;
  }
}

.pagination-container {
  max-width: 1360px;
  margin: 0 auto;
  padding-top: 40px;
  border-top: solid #aaa 1px;
  display: flex;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    text-align: left;
  }

}

.el-pagination {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #666;
}

</style>
