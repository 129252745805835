<template>
  <div class="work-container">
    <div>
      <!--navBox-->
      <Header @chooseNav="chooseNav" style="border-bottom: none !important;" :hideBorder="true" v-if="isPC"></Header>
      <MobileHeader @chooseNav="chooseNav" v-if="!isPC"></MobileHeader>
      <div v-if="!isPC" class="mobil-header-border"></div>
      <!--        <div v-if="isPC" style="width: calc(100%);height: 0;border-top: 1px solid #aaa;margin:0 auto;"></div>-->
      <NavBox @chooseNav="chooseNav" v-if="this.$route.params.navGroup !== 'graphic'" :navList="navLists"></NavBox>
      <!--workList-->
      <!--        <WorkList :isPc="isPC" :workList="workList" :nav="$route.params.nav === ''?'all':$route.params.nav" :pageIndex="$route.params.index===''?1:$route.params.index"></WorkList>-->
      <WorkList ref="workList" :isPC="isPC" :workList="workLists" :pageSize="pageSize"></WorkList>
      <!--Pagination-->
      <Pagination ref="pagination" v-if="isPC" :workCount="workCount" :pageSize="pageSize" :navList="navLists"></Pagination>
      <!--footer-->
    </div>
    <Footer @chooseNav="chooseNav"></Footer>
  </div>
</template>

<script>
import Pagination from "@/components/work/Pagination";
import WorkList from "@/components/work/WorkList";
import Footer from "@/components/index/Footer";
import Header from "@/components/common/Header";
import MobileHeader from "@/components/common/MobileHeader";
import NavBox from "@/components/work/NavBox";
import axios from 'axios'

export default {
  name: "Work",
  components: {Pagination, WorkList, Footer, Header, MobileHeader, NavBox},
  data() {
    return {
      pageSize: 12,
      navLists: [
        {nav: "全部", engNav: "all"},
        {nav: "住宅", engNav: "house"},
        {nav: "酒店", engNav: "hotel"},
        {nav: "酒吧", engNav: "bar"},
        {nav: "餐饮", engNav: "restaurant"},
        {nav: "销售", engNav: "sale"},
        {nav: "办公", engNav: "office"}
      ],
      lastPath: null,
      workCount: 0,
      workLists: []
    }
  },
  computed: {
    isPC: function () {
      var userAgentInfo = navigator.userAgent;
      console.log(userAgentInfo);
      var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod"];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      console.log(window.innerWidth);
      if (flag && window.innerWidth < 600) {
        flag = false
      }
      /*   if (window.screen.width >= 1024) {
             flag = true;
         }*/
      return flag;
    },
    /*workCount: function () {
        let num = 0;
        this.workList.map((workListByNav) => {
            // console.log(workListByNav.list);
            if (workListByNav.engNav === this.$route.params.nav) {
                workListByNav.list.map((workListPage) => {
                    num += workListPage.length
                })
            }
        })
        return num;
    },*/
    navList: function () {
      let navs = [];
      this.workList.map((workListByNav) => {
        navs.push({nav: workListByNav.nav, engNav: workListByNav.engNav})
      })
      return navs
    },
  },
  methods: {
    chooseNav() {
      this.$refs.workList.chooseNav()
    }
  },
  async created() {
    let res = await axios.get('/assets/db/workList.json');
    this.workLists = res.data.reverse()
  }
}
</script>

<style scoped>
@media only screen and (max-width: 1360px) {
  .work-container .mobil-header-border {
    width: calc(100% - 80px);
  }
}

@media only screen and (min-width: 900px) and (max-width: 1100px) {
  .work-container .mobil-header-border {
    width: calc(100% - 40px);
  }

}

@media only screen and (max-width: 900px) {
  .work-container .mobil-header-border {
    width: calc(100% - 48px);
  }

}
.work-container{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.work-container .mobil-header-border {

  height: 0;
  border-top: 1px solid #aaa;
  margin: 0 auto;
  margin-top: 20px
}

.work-container .footer {

  margin-top: 100px;

}
</style>