<template>
  <div class="footer">
    <div class="footer_wrap">
      <div class="footer_row row1">
        <div class="footer_logo"></div>
        <div class="copyright" @click="herfToCopyright">京ICP备2022018334号-1</div>
        <div class="footer_icons">
          <a href="https://weibo.com/7458206148" class="footer_icon weibo" target="_blank"></a>
          <div class="footer_icon weixin" style="overflow: visible;position: relative" @mouseenter="()=>{wechatQrCodeShow = true}" @mouseleave="wechatQrCodeShow = false">
            <Transition name="slide-fade">
              <img v-show="wechatQrCodeShow" src="assets/images/wechat-logo.jpeg" alt="">
            </Transition>

          </div>
          <a href="https://www.xiaohongshu.com/user/profile/566e3008f53ee0148ec1a385?xhsshare=CopyLink&appuid=56d57bda84edcd219511fa21&apptime=1658239704" class="footer_icon xiaohongshu" target="_blank"></a>
          <a href="https://www.instagram.com/gbspacegb/" class="footer_icon ins" target="_blank"></a>
        </div>
      </div>
      <div class="footer_row row2">
        <div class="footer_icons">
          <a href="https://weibo.com/7458206148" target="_blank" class="footer_icon weibo"></a>
          <div @click="mobileOpenWeixin" class="footer_icon weixin"></div>
          <a href="https://www.xiaohongshu.com/user/profile/566e3008f53ee0148ec1a385?xhsshare=CopyLink&appuid=56d57bda84edcd219511fa21&apptime=1658239704" target="_blank" class="footer_icon xiaohongshu"></a>
          <a href="https://www.instagram.com/gbspacegb/" target="_blank" class="footer_icon ins"></a>
        </div>
      </div>
      <div class="footer_row row3">
        <div class="copyright" @click="herfToCopyright">京ICP备2022018334号-1</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data(){
    return {
      wechatQrCodeShow:false
    }
  },
  methods: {
    herfToCopyright(){
      window.open('https://beian.miit.gov.cn')
    },
    routerlink(path) {
      this.$emit('chooseNav');
      this.$router.push(path);
    },
    mobileOpenWeixin(){
      //判断是否微信登陆
      var ua = window.navigator.userAgent.toLowerCase();
      console.log(ua);//mozilla/5.0 (iphone; cpu iphone os 9_1 like mac os x) applewebkit/601.1.46 (khtml, like gecko)version/9.0 mobile/13b143 safari/601.1
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
       window.open("https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzIwNjQ2NDA0OA==&scene=124#wechat_redirect")
      } else {
        window.open("assets/images/wechat-logo.jpeg")
      }
    }
  }
};
</script>
<style scoped lang="scss">
@media only screen and (max-width: 1360px) {
  .footer_wrap {
    width: calc(100% - 80px) !important;
    padding: 0 40px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1100px) {
  .footer_wrap {
    width: calc(100% - 40px) !important;
    padding: 0 20px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .footer {
    height: 269px !important;
  }
  .footer_wrap {
    width: calc(100% - 48px) !important;
    padding: 0 24px;


    .row1 .copyright {
      display: none;
    }

    .row3 {
      display: flex !important;
    }
  }
}

@media only screen and (max-width: 600px) {
  .footer_wrap {
    .footer_row {
      justify-content: center !important;
    }

    .row1 .copyright, .row1 .footer_icons {
      display: none !important;
    }

    .row3, .row2 {
      display: flex !important;

    }

    .row3 .copyright {
      height: calc(19px * 0.6) !important;
      line-height: calc(19px * 0.6) !important;
    }

    .row3 {
      margin-top: 30px !important;
    }

    .row2 {
      margin-top: 40px !important;
    }
  }
}

.footer {
  width: 100%;
  height: 369px;
  background-image: linear-gradient(0, rgb(136, 136, 136) 0%, rgb(242, 242, 235) 23%, rgb(242, 242, 235) 69%, rgb(255, 255, 255) 100%);

  .footer_wrap {
    width: 100%;
    max-width: 1360px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .footer_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 43px;
    }

    .row2 {
      justify-content: center;
      margin-top: 100px;
      display: none;
    }

    .row3 {
      justify-content: center;
      margin-top: 40px;
      display: none;
    }

    .footer_logo {
      width: 215px;
      height: 43px;
      background: url("~@/assets/images/footer_logo.png");
      background-size: 100% 100%;
    }

    .copyright {
      /*width: 270px;
      height: 19px;*/
      height: calc(19px * 0.6) !important;
      line-height: calc(19px * 0.6) !important;
      //background: url("~@/assets/images/footer_copyright.png");
      //background-size: 100% 100%;
    }

    .footer_icons {
      display: flex;
      width: calc(39px * 4 + 26px * 3);

      .footer_icon {
        width: 39px;
        height: 39px;
        border-radius: 39px;
        overflow: hidden;
        margin-right: 26px;
        cursor: pointer;
      }

      .weibo {
        background: url('~@/assets/images/icon_wb.png');
        background-size: 100% 100%;
      }

      .weixin {
        background: url('~@/assets/images/icon_wx.png');
        background-size: 100% 100%;

        img {
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
          width: 160px;
          position: absolute;
          bottom: 50px;
          z-index: 3;
          transform: translateX(-50%);
          left: 50%;
        }
      }
      .slide-fade-enter-active {
        transition: all 0.3s ease-out;
      }

      .slide-fade-leave-active {
        transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
      }

      .slide-fade-enter-from,
      .slide-fade-leave-to {
        transform: translateX(20px);
        opacity: 0;
      }
      .weixin:hover {

      }

      .xiaohongshu {
        background: url('~@/assets/images/icon_xhs.png');
        background-size: 100% 100%;
      }

      .ins {
        background: url('~@/assets/images/icon_ins.png');
        background-size: 100% 100%;
      }

      .footer_icon:last-child {
        margin-right: 0;
      }
    }
  }

}
</style>