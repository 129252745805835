import {createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw} from "vue-router";
import Index from "../views/Index.vue";
import About from "../views/About.vue";
import Report from "../views/Report.vue";
import Contact from "../views/Contact.vue";
import Work from "../views/Work.vue";
import Media from "../views/Media.vue";
import WorkDetail from "../views/WorkDetail.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Index",
        component: Index,
        meta: {title: "主页"}
    },
    {
        path: "/about",
        name: "About",
        component: About,
        meta: {title: "关于我们"}
    },
    {
        path: "/contact",
        name: "Contact",
        component: Contact,
        meta: {title: "联系我们"}
    },
    {
        path: "/work/:navGroup?/:nav?/:index?",
        name: "Work",
        component: Work,
        meta: {title: "作品"}
    },
    {
        path: "/media",
        name: "Media",
        component: Media,
        meta: {title: "媒体发布及奖项"}
    },
    {
        path: "/detail/:workId?/:workTitle?",
        name: "WorkDetail",
        component: WorkDetail
    }
];


const router = createRouter({
    history: createWebHistory("/"),
    // history: createWebHashHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    // console.log(process.env.NODE_ENV);
    if (to.name === "WorkDetail") {
        console.log(to.params);
        let {workId, workTitle}= to.params
        // window.document.title = workTitle + " - 北京万义斋科技有限公司";
        window.document.title = workTitle + " - Wave.GB";
    }else{
        // @ts-ignore
        // window.document.title = to.meta.title + " - 北京万义斋科技有限公司";
        window.document.title = to.meta.title + " - Wave.GB";
    }


    next();
    document.documentElement.scrollTop = 0;
});


export default router;
