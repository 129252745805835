<template>
    <div class="nav-container">
        <div class="nav-box">
            <div :class="{active:$route.params.nav === item.engNav}" @click="chooseNav(index)" class="nav" v-for="(item,index) in navList">{{item.nav}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NavBox",
        props: ['navList'],
        data() {
            return {

            }
        },
        methods: {
            chooseNav(index) {
                console.log(this.navList[index].engNav);
                let path = `/work/${this.$route.params.navGroup}/${this.navList[index].engNav}/${1}`;
                if (this.$parent.lastPath === path) return;
                this.$parent.lastPath = path;
                this.$router.push(path);
                this.$emit("chooseNav")
            }
        }
    }
</script>

<style scoped lang="scss">
    @media only screen and (max-width: 1360px) {
        .nav-box {
            margin-right: 40px;
        }
    }

    @media only screen and (min-width: 900px) and (max-width: 1100px) {
        .nav-box {
            margin-right: 20px;
        }

    }

    @media only screen and (max-width: 900px) {
        .nav-box {
            margin-right: 24px;
        }

    }

    @media only screen and (max-width: 600px) {
      .nav {
        font-size: 16px;
      }

    }

    .nav-container {
        max-width: 1360px;
        margin: 0 auto;
        padding-top: 20px;
        overflow: hidden;

        .nav-box {
            float: right;

            .nav {
                float: left;
                width: 44px;
                margin: 0 2px;
                line-height: 22px;
                font-weight: 400;
                font-size: 18px;
                color: #555;
                transition: all 300ms;
                text-decoration-line: none;
            }

            .nav:after {
                content: "";
                width: 0%;
                height: 0.1px;
                background: #000;
                display: block;
                margin: 0 auto;
                transition: all 0.2s;
            }

            .nav:hover {
                color: #000;
            }

            .nav:hover::after {
                width: 100%;
            }


            .nav.active {
                color: #000;
                font-weight: bolder;
            }
        }
    }
</style>