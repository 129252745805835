<template>
    <div class="pagination-wrapper">
        <div class="goPre" :disabled="preDisable" @click="goPre">
            <svg width="10" height="24px" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1L1 9L9 17" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div> <!--上一页按钮-->
        <ul>                                                         <!--页码列表-->
            <li v-for="index in pages" :key="index" ref="pages" v-show="isShowBtn(index) || isShowEllipsis (index)">
                <div @click="jumpToPage(index)" v-if="isShowBtn(index)" :class="current===index?'active':''">{{index}}</div>
                <div v-else-if="isShowEllipsis (index)" class="ellipsis">&#8230;</div> <!--省略号-->
            </li>
        </ul>
        <div class="goNext" :disabled="nextDisable" @click="goNext">
            <svg width="10" height="24" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L9 9L1 17" stroke="#777777" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div> <!--上一页按钮-->
    </div>
</template>

<script>
    export default {
        props: {
            total: {
                type: Number,
                default: 200
            },
            pagerCount: {
                type: Number,
                default: 4
            },
            pageSize: {
                type: Number,
                default: 12
            },
            navList: {}
        },
        data() {
            return {
                current: 1, // 定义当前页current
                pages: [], // 页码列表pages
                pageLength: 0 // 页码长度pageLength
            }
        },
        computed: {
            preDisable() { // 是否禁用上一页
                return this.current === 1
            },
            nextDisable() { // 是否禁用下一页
                return this.current === this.pageLength
            }
        },
        watch: {
            total(val) { // 监听数据总数total的改变在计算页码列表getPagesLength()
                this.getPagesLength()
            },
            current(val) { // 监听当前页current改变，向父组件传递参数当前页
                this.$emit('change', val)
            }
        },
        created() { // 初始化计算页码列表getPagesLength()
            this.getPagesLength()
        },
        methods: {
            getPagesLength() { // 计算页码列表
                const more = this.total % this.pageSize ? 1 : 0
                this.pageLength = Math.ceil(this.total / this.pageSize)
                console.log(this.pageLength);
                this.pages = new Array(this.pageLength)
                for (let i = 0; i < this.pageLength; i++) {
                    this.pages[i] = i + 1
                }
            },
            jumpToPage(index) {
                this.current = index
                console.log(this.$route);
                let path = `/work/${this.$route.params.navGroup}/${this.$route.params.nav}/${this.current}`;
                if (this.$parent.$parent.lastPath === path) return;
                this.$parent.$parent.lastPath = path;
                this.$router.push(path);
                // this.$router.push(`/work/${this.$route.params.nav}/${this.current}`)
            },
            goPre() { // 上一页
                this.current -= this.current === 1 ? 0 : 1
                let path = `/work/${this.$route.params.navGroup}/${this.$route.params.nav}/${this.current}`;
                if (this.$parent.$parent.lastPath === path) return;
                this.$parent.$parent.lastPath = path;
                this.$router.push(path);
            },
            goNext() { // 下一页
                this.current += this.current === this.pageLength ? 0 : 1
                let path = `/work/${this.$route.params.navGroup}/${this.$route.params.nav}/${this.current}`;
                if (this.$parent.$parent.lastPath === path) return;
                this.$parent.$parent.lastPath = path;
                this.$router.push(path);
            },
            isShowBtn(index) {
                if (this.pageLength < this.pagerCount) {
                    return true
                } else {
                    if (index === 1 || index === this.pageLength) {
                        return true
                    } else {
                        // console.log(this.current, index);
                        if (this.current < 2 && index < 4) {
                            // console.error("==1=")
                            return true
                        } else if (this.current > this.pageLength - 2 && index > this.pageLength - 3) {
                            // console.error("==2=")
                            return true
                        } else if (index < this.current + 2 && index > this.current - 1) {
                            // console.error("==3=")
                            return true
                        } else {
                            return false
                        }
                    }
                }
            },
            isShowEllipsis(index) { // 是否显示省略号
                return index === 2 || index === this.pageLength - 1
            }
        }
    }
</script>

<style lang="scss" scoped>
    @media only screen and (max-width: 950px) {
        .pagination-container {
            flex-direction: column-reverse;

            .pagination-wrapper {
                margin-left: 0 !important;
                align-self: center !important;
                margin-bottom: 40px;

                ul {
                    padding: 0 20px;

                    li {
                        font-size: 24px !important;
                        line-height: 36px !important;
                        margin: 0 16px;
                    }
                }

                svg {
                    height: 36px;
                }
            }
        }

    }

    .pagination-wrapper {
        flex-shrink: 0;
        margin-left: 10%;
        align-self: flex-end;

        ul {
            /*display: flex;*/
            list-style: none;
            margin: 0;
            float: left;
            padding: 0 10px;

            li {
                float: left;
                margin: 0 12px;
                display: block;
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 23px;
                color: #666666;

            }

            .active {
                color: black;
            }

            .ellipsis {
                font-weight: bold;
                color: #999;
                line-height: 24px
            }

            button {
                height: 30px;
                width: 30px;
                margin: 0 5px;
                color: #777;
                font-weight: bold;
                background: #fff;
                overflow: hidden;
                user-select: none;

                &:hover {
                    cursor: pointer;
                }

                &:disabled {
                    color: #ccc;
                    cursor: not-allowed
                }
            }
        }

        .goNext {
            float: left
        }

        .goPre {
            float: left
        }
    }
</style>

