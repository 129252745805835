<template>
  <div class="work-detail">
    <div class="work-detail-banner" v-if="work">
      <Header v-if="isPc" class="index-header" :navBarColor="work.banner.pc.headerTheme"></Header>
      <MobileHeader v-if="!isPc" class="index-header" :navBarColor="work.banner.mobile.headerTheme"></MobileHeader>
      <ImgPlus :src="work.banner"></ImgPlus>
      <!--            <img :src="work.bannerPic">-->
    </div>
    <div class="work-detail-container" v-if="work">
      <div class="work-detail-breadcrumb"><span
          @click="breadcrumbLink(work.navGroup,'')">{{ dict[work.navGroup] }}</span> <span
          style="margin: 0 8px">/</span><span
          @click="breadcrumbLink(work.navGroup,work.engNav)">{{ dict[work.engNav] }}</span></div>
      <div class="work-detail-title">{{ work.title }}</div>
      <div class="work-detail-subtitle">{{ work.subTitle }}</div>
      <div v-for="item in work.rich" style="overflow: hidden" :class="item.name">
        <ImgPlus v-if="item.name === 'main'" class="work-desc-main-pic" :src="item.component"></ImgPlus>
        <div v-if="item.name === 'text'" class="work-desc" v-html="item.component.content"></div>
        <div class="work-pic-box" v-if="item.name === 'picBox'">
          <div class="work-pic-box-item" v-for="subPic in item.picBox">
            <ImgPlus :src="subPic"></ImgPlus>
          </div>
        </div>
      </div>

      <div class="work-detail-more-title">相关项目</div>
      <div class="work-detail-more">
        <div v-for="item in relatedWorks" class="work-detail-more-item"
             :class="'work-detail-more-num-'+relatedWorks.length"
             @click="this.$router.push(`/detail/${item.id}/${item.title}`)">
          <ImgPlus :src="item.poster" :parentRatio="3 / 2"></ImgPlus>
          <div class="card-mask"></div>
          <div class="card-desc-box">
            <div class="card-desc-title">{{ item.title }}</div>
            <div class="card-desc">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@/components/index/Footer';
import Header from '@/components/common/Header';
import MobileHeader from '@/components/common/MobileHeader';
import ImgPlus from '@/components/common/ImgPlus';
import axios from 'axios';

export default {
  name: 'WorkDetail',
  components: {
    Footer,
    Header,
    MobileHeader,
    ImgPlus
  },
  data() {
    return {
      workLists: [],
      dict: {
        spaceDesign: '空间设计',
        graphicDesign: '平面设计',
        'all': '全部',
        'house': '住宅',
        'hotel': '酒店',
        'restaurant': '餐饮',
        'sale': '销售',
        'office': '办公',
      },
      relatedWorks: []
    };
  },
  computed: {
    isPc: function () {
      if (window.innerWidth / window.innerHeight > 1) {
        return true;
      }
      else {
        return false;
      }
    },
    work: function () {
      let workId = this.$route.params.workId ? Number(this.$route.params.workId) : 1;
      let currentWork = null;
      let workListByNav = {};
      for (let i = 0; i < this.workLists.length; i++) {
        let work = this.workLists[i];
        if (work.navGroup === 'graphicDesign') {
          if (!workListByNav['graphicDesign']) workListByNav['graphicDesign'] = [];
          workListByNav['graphicDesign'].push(work);
        }
        else {
          if (!workListByNav[work.navGroup]) workListByNav[work.navGroup] = {};
          if (!workListByNav[work.navGroup][work.engNav]) workListByNav[work.navGroup][work.engNav] = [];
          workListByNav[work.navGroup][work.engNav].push(work);
        }
        if (work.id === workId) {
          currentWork = work;
        }
      }
      let relatedWorksSet = new Set();
      relatedWorksSet.add(currentWork);
      if (currentWork) {
        console.log('currentWork.navGroup', currentWork.navGroup);
        if (currentWork.navGroup === 'graphicDesign') {
          for (let j = 0; j < 5; j++) {
            if (workListByNav['graphicDesign'][j])
              relatedWorksSet.add(workListByNav['graphicDesign'][j]);
          }
        }
        else {
          for (let j = 0; j < 5; j++) {
            if (workListByNav[currentWork.navGroup][currentWork.engNav][j])
              relatedWorksSet.add(workListByNav[currentWork.navGroup][currentWork.engNav][j]);
          }
        }
        let relatedWorks = Array.from(relatedWorksSet);
        this.relatedWorks = [];
        for (let j = 1; j <= 3; j++) {
          if (relatedWorks[j]) this.relatedWorks.push(relatedWorks[j]);
        }
      }

      return currentWork;
    }
  },
  async created() {

    let res = await axios.get('/assets/db/workList.json');
    this.workLists = res.data;
  },
  methods: {
    breadcrumbLink(navGroup, engNav) {
      let navGroupdict = {
        graphicDesign: 'graphic',
        spaceDesign: 'space'
      };
      console.log(engNav, engNav === '');
      // if (navGroup === "space" && engNav === '') engNav = 'all'
      this.$router.push(`/work/${navGroupdict[navGroup]}/${navGroup === 'spaceDesign' && engNav === '' ? 'all' : engNav}`);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../components/common/common.scss";

@media only screen and (max-width: 1100px) {
  .work-detail-container {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1100px) {
  .work-detail-container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media only screen and (max-width: 900px) {
  .work-detail-container {
    .work-detail-more .work-detail-more-item {
      width: calc(50% - 5px) !important;
      padding-bottom: calc(50% * 2 / 3) !important;
    }

    .work-detail-more-title {
      font-size: $font-size-18 !important;
      line-height: $line-height-18 !important;
      margin-top: 60px !important;
    }

    .work-detail-more .work-detail-more-item:first-child {
      margin-right: 10px;
    }

    .work-detail-more .work-detail-more-item:nth-child(3) {
      display: none;
    }
  }
  .work-detail-container {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .work-desc {
    font-size: $font-size-16 !important;
    /*margin-top: 40px !important;*/
    margin-top: 50px !important;
    /*margin-bottom: 20px !important;*/
    margin-bottom: 30px !important;
  }
  .zh{
    letter-spacing: 15px;
  }
  .work-detail-title {
    margin-top: 40px !important;
    margin-bottom: 10px !important;
  }
  .work-detail-subtitle{
    margin-bottom: 30px !important;
    font-size: $font-size-14 !important;
    letter-spacing: 1px !important;
  }
  .work-desc-main-pic {
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 1100px) {
  .work-detail-container .work-desc {
    width: 100% !important;
    max-width: none !important;
  }
}

@media only screen and (min-width: 400px) and (max-width: 1100px) {
  .work-detail-container {
    .work-pic-box .work-pic-box-item {
      width: calc(50% - 5px) !important;
      margin-bottom: 10px !important;
    }

    .work-pic-box .work-pic-box-item:nth-child(odd) {
      margin-right: 10px !important;
    }
  }
}

@media only screen and (max-width: 400px) {
  .work-detail-container {
    .work-pic-box .work-pic-box-item {
      width: 100% !important;
      margin-right: 0 !important;
    }
    .work-detail-more .work-detail-more-item.work-detail-more-num-1 {
      width: 100% !important;
      padding-bottom: calc(100% * 2 / 3) !important;
    }
  }
}

.text + .text {
  .work-desc {
    margin-top: 0 !important;
  }
}

.work-detail-container {
  max-width: 1100px;
  margin: 0 auto;
  text-align: left;

  .work-detail-breadcrumb {
    font-weight: $font-weight-border;
    font-size: $font-size-16;
    line-height: $line-height-16;
    color: #555555;
    text-align: left;
    display: inline-block;
    padding-bottom: 10px;
    margin-top: 100px;
    border-bottom: 1px solid #AAAAAA;
  }

  .work-detail-title {
    font-weight: $font-weight-border;
    font-size: 32px;
    line-height: 38px;
    margin-top: 60px;
    margin-bottom: 16px;
    color: #333;
  }

  .work-detail-subtitle {
    color: #666;
    font-size: $font-size-16;
    line-height: $line-height-18;
    height: $line-height-18;
    margin-bottom: 10px;
  }

  .work-desc-main-pic {
    width: 100%;
    margin-top: 40px;
  }

  .work-desc {
    max-width: 1100px;
    width: 100%;
    font-weight: $font-weight-normal;
    font-size: $font-size-18;
    line-height: 27px;
    color: #666666;
    margin-top: 80px;
    margin-bottom: 40px;
    text-align: left;
    float: left;
  }

  .work-pic-box {
    clear: both;
    padding-top: 40px;
    overflow: hidden;

    .work-pic-box-item {
      width: calc(50% - 10px);
      float: left;
      /*margin-bottom: 20px;*/
      margin-bottom: 0px;
    }

    .work-pic-box-item:nth-child(odd) {
      margin-right: 20px;
    }
  }

  .work-detail-more-title {
    font-weight: $font-weight-border;
    font-size: $font-size-20;
    line-height: $line-height-20;
    /*text-decoration-line: underline;*/
    color: #333333;
    margin-top: 80px;
  }

  .work-detail-more {
    width: 100%;
    margin-top: 40px;
    overflow: hidden;
    cursor: pointer;

    .work-detail-more-num-1 {
      width: 33.3333%;
      padding-bottom: calc(33.3333% * 2 / 3);
      /*width: 100%;
      padding-bottom: calc(100% * 2 / 3);*/
    }

    .work-detail-more-num-2 {
      width: 50%;
      padding-bottom: calc(50% * 2 / 3);
    }

    .work-detail-more-num-3 {
      width: 33.3333%;
      padding-bottom: calc(33.3333% * 2 / 3);
    }

    .work-detail-more-item {
      float: left;
      margin-bottom: 40px;
      position: relative;
      overflow: hidden;

      .card-mask {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0);
        transition: all .5s;
      }

      .card-desc-box {
        position: absolute;
        width: 100%;
        height: 100%;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: white;
        opacity: 0;
        transition: all .5s;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .card-desc-title {
          font-size: 18px;
          margin: 3px 0;
        }

        .card-desc {
          margin: 3px 0;
        }

      }


      .p-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.5s;
        /*.p-img{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }*/
      }

      /*img {
          transition: all 0.5s;
          width: 100%;
      }*/
    }

    .work-detail-more-item:hover {
      .p-container {
        transform: translate(-50%, -50%) scale(1.1);
      }

      .card-mask {
        background: rgba(0, 0, 0, 0.7);
      }

      .card-desc-box {
        opacity: 1;
      }
    }
  }
}

.work-detail-banner {
  width: 100%;
  position: relative;
}

.index-header {
  background: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  margin: 0 auto;
  z-index: 20;
  width: 100%;
  left: 0;
  right: 0;
  border-bottom: 0;
  transition: color 0.5s;
}
</style>