<template>
  <div ref="pContainer" class="p-container" :class="fillMode">
    <img @mouseover="videoMouseover"
             @mouseleave="videoMouseleave" class="p-img p-video-poster" v-if="compAttr().type==='video' && !videoIsPlay" :src="compAttr().attr.poster"/>
    <video @canplay="test" :id="'video_'+vid" ref="video" :autoplay="!compAttr().attr.playOnHover"
            :muted="muted || outerMuted" loop
           @mouseover="videoMouseover"
           @mouseleave="videoMouseleave"
           v-if="compAttr().type==='video'"
           webkit-playsinline=""
           playsinline=""
           x-webkit-airplay="deny"
           x-webkit-wirelessvideoplaybackdisabled=""
           :src="compAttr().attr.src"
           @play="onPlay"
           @pause="onPause"
           class="p-img"
           style="object-fit: cover;"></video>
    <img v-else :src="compAttr().attr.src" :alt="windowRatio" class="p-img">
  </div>
</template>

<script>
export default {
  name: 'ImgPlus',
  props: ['src', 'parentRatio','outerMuted'],
  data() {
    return {
      windowRatio: null,
      random: '',
      vid: '',
      videoIsPlay:false,
      muted:true
    };
  },
  computed: {
    deviceType: function () {
      let windowRatio = this.windowRatio || window.innerWidth / window.innerHeight;
      if (windowRatio > 1) {
        return 'pc';
      } else {
        return 'mobile';
      }
    },
    isPic(src) {
      const PICTURE_EXPRESSION = /\.(png|jpe?g|gif|svg)(\?.*)?$/;
      const picReg = new RegExp(PICTURE_EXPRESSION);
      return (src.match(picReg) != null);
    },
    fillMode() {
      let currentAttr = this.src.hasOwnProperty('all') ? this.src['all'] : this.src[this.deviceType];
      // console.log('currentAttr', currentAttr);
      let fillMode = currentAttr.fillMode || 'fullParent';
      // console.log(fillMode);
      if (fillMode === 'autoFullScreen' || fillMode === 'autoFullParent') {
        let parentRatio = null;
        if (fillMode === 'autoFullScreen') parentRatio = this.windowRatio || window.innerWidth / window.innerHeight;
        else parentRatio = this.parentRatio;
        let imgType = 'pic';
        if (currentAttr.video && currentAttr.video.poster && currentAttr.video.src && currentAttr.video.poster !== '' && currentAttr.video.src !== '') imgType = 'video';
        if (currentAttr[imgType].ratio > parentRatio) return [fillMode, 'w-auto_h-100'];
        else return [fillMode, 'w-100_h-auto'];
      } else if (fillMode === 'fullParent') {
        return 'w-100_h-100';
      } else if (fillMode === 'fullWidth') {
        return 'w-100_h-auto';
      } else if (fillMode === 'halfHeight') {
        return [fillMode, 'w-auto_h-80'];
      }
    }
  },
  created() {
    this.vid = this.guid();
    this.windowRatio = window.innerWidth / window.innerHeight;
    window.addEventListener('resize', () => {
      this.windowRatio = window.innerWidth / window.innerHeight;
    });
  },
  mounted() {
    if (this.$refs.video && !this.compAttr().attr.playOnHover) {
      let videos = document.getElementById('video_' + this.vid);
      videos.onpause = () => {
        this.$refs.video.play();
      };
    }
  },

  methods: {
    onPlay(){
      console.warn("播放===")
      this.videoIsPlay = true
      console.log('====')
      window.addEventListener('click',()=>{
        console.log('====================click========')
        this.muted = false
      })
    },
    onPause(){
      console.warn("暂停===")
      this.videoIsPlay = false
    },
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    compAttr() {
      let compAttr = this.src.hasOwnProperty('all') ? this.src['all'] : this.src[this.deviceType];
      if (compAttr.video && compAttr.video.poster && compAttr.video.src && compAttr.video.poster !== '' && compAttr.video.src !== '') {
        return {
          type: 'video',
          attr: compAttr.video
        };
      } else {
        return {
          type: 'pic',
          attr: compAttr.pic
        };
      }
    },
    videoMouseover(e) {
      console.log('videoMouseover');
      if (this.compAttr().attr.playOnHover) {
        e.target.play()
      }
    },
    videoMouseleave(e) {
      console.log('videoMouseover');
      if (this.compAttr().attr.playOnHover) {
        e.target.pause()
        e.target.currentTime = 0;
      }
    },
    test(a) {
      // a.target.play()
      // console.log(a.target.videoWidth, a.target.videoHeight);
    },
    initVideo(nowPlayVideoUrl) {
      /*if (this.compAttr().type !== 'video') {
        return;
      }*/
      // console.warn('initVideo');
      // 这些options属性也可直接设置在video标签上，见 muted
      let options = {
        // autoplay: true, // 设置自动播放
        controls: false, // 显示播放的控件
        controlBar: false,
        LoadProgressBar: false,
        LoadingSpinner: false,
        TextTrackDisplay: false,
        ProgressControl: false,

        SeekBar: false,
        /*sources: [
          // 注意，如果是以option方式设置的src,是不能实现 换台的 (即使监听了nowPlayVideoUrl也没实现)
          {
            src: this.compAttr().attr.src,
            type: 'application/x-mpegURL' // 告诉videojs,这是一个hls流
          }
        ]*/
      };
      let videoPlayer = this.$refs.videoplayer;
      if (!videoPlayer) return;
      let that = this;
      this.myPlyer = Videojs(videoPlayer, options, function onPlayerReady() {
        that.ready = true;
        this.muted(true);
        // this.play()
      });
    },
    async play() {
      // this.myPlyer.reset()
      // console.log('play');
      // console.log(this.myPlyer, this.ready);
      if (this.myPlyer && this.ready) {
        // await this.$nextTick()
        // this.myPlyer.currentTime(0)
        this.myPlyer.play();
      }
    }
  }
};
</script>

<style scoped lang="scss">
/*pc*/
@media only screen and (min-aspect-ratio: 100/100) {

}

/*mobile*/
@media only screen and (max-aspect-ratio: 100/100) {

}
video{
  transform: scale(1.005);
}
.p-video-poster{
  z-index: 2;
  pointer-events: none;
  position: absolute;
}
.p-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.p-container.autoFullScreen {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.p-container.autoFullParent {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.p-video {
  width: 100%;
  height: 100%;
}

.p-container.w-100_h-auto {
  transform: translateY(-0.8px);
  .p-img {
    //position: absolute;
    width: 100% !important;
    height: auto !important;
  }

  .p-video video {
    width: 100% !important;
    height: auto !important;
  }
}
.p-container.w-auto_h-80{
  height: 80vh;
  overflow: hidden;
  .p-img {
    position: unset !important;
    width:  auto !important;
    height:100% !important;
  }
}
.p-container.w-auto_h-100 {
  .p-img {
    //position: absolute;
    width: auto !important;
    height: 100% !important;
  }

  .p-video video {
    width: auto !important;
    height: 100% !important;
  }
}

.p-container.w-100_h-100 {
  .p-img {
    width: 100% !important;
    height: 100% !important;
  }

  .p-video video {
    width: 100% !important;
    height: 100% !important;
  }
}

.p-container.halfHeight {
  position: relative;
  overflow: hidden;

  .p-img {
    position: absolute;
  }

  .p-video {
    position: relative;
  }

  .p-video video {
    position: absolute;
  }
}
</style>
<style>
.vjs-tech {
  width: 100%;
}

.vjs-modal-dialog-content {
  display: none;
}

.vjs-error-display {
  display: none;
}

.vjs-loading-spinner {
  display: none !important;
}

.vjs-poster {
  background-color: rgba(0, 0, 0, 0);
}

.w-auto_h-100 .vjs-poster {
  background-size: auto 100%;
  background-position: left center;
}

.vjs-control-bar {
  display: none !important;
}

.vjs-big-play-button {
  display: none !important;
}
</style>