<template>
  <div style="max-width: 1360px;margin: 0 auto;height: 1px;background: #ccc"></div>
  <div class="awards" v-if="awards.length=== 5">
    <div class="awards-title">
      <p>作品曾获</p>
      <p>Awards</p>
    </div>
    <div class="awards-content">
      <!--      <img class="awards-photo-wall" src="assets/images/awards_photo_wall.jpg">-->
      <div class="awards-photo-wall">
        <div class="wall_row">
          <a class="wall_award" target="_blank" :href="awards[0].href">
            <ImgPlus class="card-bg" :src="awards[0]" :parentRatio="250 / 194"></ImgPlus>
          </a>
          <a class="wall_award" target="_blank" :href="awards[1].href">
            <ImgPlus class="card-bg" :src="awards[1]" :parentRatio="250 / 194"></ImgPlus>
          </a>
        </div>
        <a class="wall_row full_width" target="_blank"
           :href="awards[2].href">
          <ImgPlus class="card-bg" :src="awards[2]" :parentRatio="500 / 196"></ImgPlus>
        </a>
        <div class="wall_row">
          <a class="wall_award" target="_blank" :href="awards[3].href">
            <ImgPlus class="card-bg" :src="awards[3]" :parentRatio="250 / 194"></ImgPlus>
          </a>
          <a class="wall_award" target="_blank" :href="awards[4].href">
            <ImgPlus class="card-bg" :src="awards[4]" :parentRatio="250 / 194"></ImgPlus>
          </a>
        </div>
      </div>
      <div class="awards-text-box">
        <div class="awards-text awards-text-1">
          <p>2019-2021 Brand VI 创意呈现年鉴</p>
          <p>2020 入选安邸AD100YOUNG</p>
          <p>2020 作品入选 ELLE DECORATION 家居廊《2020中国室内设计年鉴》</p>
          <p>2020-2021 中国设计星全国 12 强</p>
          <p>2019 中国陶瓷创新产品与空间应用设计大赛优秀设计师</p>
          <p>2019 红棉中国设计奖2019室内设计奖</p>
          <p>2018-2019 40UNDER40 中国设计杰出青年</p>
          <p>2018 靳埭强设计奖专业组铜奖</p>
          <p>2017 第七届 BEST100 中国最佳设计100强</p>
        </div>
        <div class="awards-text awards-text-2">
          <p>2019-2021 Brand Creation Realization VI</p>
          <p>2020 Selected for AD100YOUNG</p>
          <p>2020 Selected into China Interior Design Annual 2020 by ELLE DECORATION</p>
          <p>2020-2021 Top 12 of Chinese Design Elites</p>
          <p>2019 Outstanding designer of Space Application Ceramic Original</p>
          <p>2019 Kapok Design Awards China Interior Architecture Winner 2019</p>
          <p>2018-2019 40UNDER40 China Design Outstanding Youth</p>
          <p>2018 Bronze Award in Professional Category of Kan Tai-Keung Design Award</p>
          <p>2017 The 7th BEST100 Top 100 China Best Designers</p>
        </div>
      </div>
    </div>
  </div>
  <div style="max-width: 1360px;margin: 0 auto;height: 1px;background: #ccc"></div>
</template>

<script>
import ImgPlus from '@/components/common/ImgPlus';

export default {
  name: 'Awards',
  components: {ImgPlus},

  props: ['awards']

};
</script>

<style scoped lang="scss">
@import "../../components/common/common.scss";

/*@media only screen and (min-width: 801px) and (max-width: 1200px) {
}*/

@media only screen and (min-width: 900px) and (max-width: 1100px) {
  .awards {
    padding: 20px !important;
  }
  .awards-photo-wall {
    margin-right: 20px !important;
  }
}

@media only screen and (min-width: 650px) and (max-width: 900px) {
  .awards {
    padding: 40px !important;
  }
  .awards-content {
    display: block !important;
  }
  .awards-photo-wall {
    width: 100% !important;
    height: auto !important;
    padding: 0 !important;
  }
  .awards-text-box {
    padding-top: 20px;
    flex-direction: row !important;
  }
  .awards-text {
    width: 46%;
  }
}

@media only screen and (max-width: 650px) {
  .awards {
    padding: 24px !important;
  }
  .awards-content {
    display: block !important;
  }
  .awards-photo-wall {
    width: 100% !important;
    height: auto !important;
    padding: 0 !important;
  }
  .awards-text-box {
    padding-top: 20px;
    display: block;
  }
  .awards-text {
    width: 100%;
  }
  .awards-text-2 {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 450px) {
  .awards-text {
    font-size: 14px !important;
  }
}


.awards {
  color: black;
  max-width: 1360px;
  margin: 0 auto;
  padding: 40px;
  padding-bottom: 70px;
  /*  border-bottom: 1px solid #AAAAAA;
    border-top: 1px solid #AAAAAA;*/

  .awards-title {
    text-align: left;
    font-family: $font-family;
    font-style: $font-style;
    font-weight: $font-weight-border;
    font-size: $font-size-20;
    line-height: $line-height-20;
    margin-bottom: 30px;
    color: #333;
  }

  .awards-content {
    display: flex;
    justify-content: space-between;

    .awards-photo-wall {
      width: 500px;
      height: 584px;
      margin-right: 60px;

      .wall_row {
        width: 100%;
        height: 0;
        padding-bottom: calc(194 / 500 * 100%);
        display: flex;

        .card-bg {
          position: absolute;
        }

        .wall_award {
          width: 50%;
          height: 0;
          padding-bottom: calc(194 / 500 * 100%);
          position: relative;
        }
      }

      .wall_row.full_width {
        padding-bottom: calc(196 / 500 * 100%);
        position: relative;
      }
    }

    .awards-text-box {
      display: flex;
      flex-direction: column;
      /*height: 584px;*/
      justify-content: space-between;

      .awards-text {
        font-family: $font-family;
        font-style: $font-style;
        font-weight: $font-weight-normal;
        font-size: $font-size-16;
        line-height: 22px;
        text-align: left;
        flex-wrap: wrap;
        color: #666666;
      }

      .awards-text-2 {
        align-self: flex-end;
      }
    }
  }


}
</style>