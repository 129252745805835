<template>
    <Header v-if="isPC"></Header>
    <MobileHeader v-if="!isPC"></MobileHeader>
    <div class="about_container">
        <div class="banner-box">
            <img class="banner" src="assets/images/about_banner.png"/>
            <div class="banner-text">
                <p>LA CHANSONNIERE</p>
                <p>蘭颂西餐厅</p>
            </div>
        </div>
        <Slogn></Slogn>
        <Clients></Clients>
        <Awards></Awards>
        <OurTeam></OurTeam>
<!--        <img class="about-pic-end" src="assets/images/about_pic_end.png"/>-->
    </div>
    <Footer style="margin-top: 100px"></Footer>
</template>

<script>
    import Header from "@/components/common/Header";
    import Footer from "@/components/index/Footer";
    import Awards from "@/components/about/Awards";
    import Clients from "@/components/about/Clients";
    import Slogn from "@/components/about/Slogn";
    import OurTeam from "@/components/about/OurTeam";
    import MobileHeader from "@/components/common/MobileHeader";

    export default {
        name: "About",
        components: {Header, Footer, Awards, Clients, Slogn, OurTeam, MobileHeader},
        data() {
            return {
            }
        },
        computed: {
            isPC: function () {
                var userAgentInfo = navigator.userAgent;
                console.log(userAgentInfo);
                var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod"];
                var flag = true;
                for (var v = 0; v < Agents.length; v++) {
                    if (userAgentInfo.indexOf(Agents[v]) > 0) {
                        flag = false;
                        break;
                    }
                }
                console.log(window.innerWidth);
                if (flag && window.innerWidth < 600){
                    flag = false
                }
               /* if (window.screen.width >= 768) {
                    flag = true;
                }*/
                return flag;
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../components/common/common.scss";

    @media only screen and (max-width: 1360px){
        .banner-text {
            margin-top: 20px !important;
            padding-right: 40px;
        }
    }
    @media only screen and (min-width: 900px) and (max-width: 1360px){
        .banner-text {
            padding-right: 20px;
            font-size: 14px !important;
        }
    }

    @media only screen and (max-width: 900px){
        .banner-text {
            margin-top: 12px !important;
            padding-right: 24px;
        }
    }

    @media only screen and (min-width: 700px) and (max-width: 900px){
        .banner-text {
            font-size: 14px !important;

        }
    }

    @media only screen and (max-width: 700px){
        .banner-text {
            font-size: 12px !important;

        }
    }

    .about_container {
        max-width: 1360px;
        margin: 0 auto;
    }

    .banner-box {
        width: 100%;

        .banner {
            width: 100%;
            margin-top: 20px;
        }

        .banner-text {
            margin-top: 10px;
            font-family: $font-family;
            font-style: $font-style;
            font-weight: $font-weight-normal;
            font-size: 16px;
            line-height: 19px;
            text-align: right;
            color: #666666;
        }
    }

    @media only screen and (max-width: 500px) {
        .about-pic-end {
            width: calc(100% - 48px) !important;
            /*margin: 0 auto;*/
        }
    }

    .about-pic-end {
        width: 500px;
        margin: 0 auto;
        margin-top: 80px;
    }
</style>