<template>
  <div style="width: 100%">
    <div class="header" :class="{'white-header':navBarColor === 'white'}">
      <div class="logo" @click="$router.push('/')">
        <transition name="fade">
          <div class="whiteLogo" v-show="navBarColor === 'white'"></div>
        </transition>
        <transition name="fade">
          <div class="blackLogo" v-show="navBarColor !== 'white'"></div>
        </transition>
      </div>
      <div class="nav-container">
        {{ activeNav }}
        <div class="nav" :class="{'active':$route.name === 'Work' || $route.name === 'WorkDetail'}"
             style="position: relative"><span>作品</span>
          <div class="sub-nav-container" style="position: absolute">
            <div class="nav" style="margin-top: 10px" @click="$router.push('/work/space/all')">空间设计</div>
            <div class="nav" style="margin-top: 5px" @click="$router.push('/work/graphic')">平面设计</div>
          </div>
        </div>
        <div class="nav" :class="{'active':$route.name === 'Media'}" @click="$router.push('/media')">媒体</div>
        <div class="nav" :class="{'active':$route.name === 'About'}" @click="$router.push('/about')">关于我们</div>
        <div class="nav" :class="{'active':$route.name === 'Contact'}" @click="$router.push('/contact')">联系我们</div>
<!--        <div class="nav">EN</div>-->
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: ['navBarColor'],
  data() {
    return {
      btnType: true
    };
  },
  computed: {
    activeNav() {
      console.log(this.$route.name);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../components/common/common.scss";

@media only screen and (max-width: 1360px) {
  .header {
    padding-left: 40px;
    padding-right: 40px;
    width: calc(100% - 80px) !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1100px) {
  .header {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media only screen and (max-width: 900px) {
  .header {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}

.index-header {

  position: absolute;
  top: 0;
  margin: 0 auto;
  z-index: 20;
  width: 100%;
  left: 0;
  right: 0;

  .header {
    background: rgba(0, 0, 0, 0) !important;
    border-bottom: 0;
    transition: color 0.5s;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.header {
  padding-top: 40px;
  padding-bottom: 70px;
  background: white;
  max-width: 1360px;
  margin: 0 auto;
  font-family: $font-family;
  font-style: $font-style;
  //font-weight: $font-weight-border;
  font-size: 22px;
  line-height: 28px;
  border-bottom: solid 1px #ccc;
  overflow: hidden;
  color: #333;

  .whiteLogo {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: url("../../assets/images/white_logo_1.png");
    background-size: 100% 100%;
  }
.blackLogo{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: url("../../assets/images/black_logo_1.png");
  background-size: 100% 100%;
}
  .logo {
    width: 64px;
    height: 64px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    position: relative;
    cursor: pointer;
  }

  .nav-container {
    float: right;
    padding-top: 20px;

    .nav {
      float: left;
      margin: 0 18px;
      text-align: center;
      cursor: pointer;
      padding-bottom: 1px;

      .sub-nav-container {
        width: 100px;
        margin-top: 3px;
        height: 0;
        overflow: hidden;
        transition: all 0.3s;
        opacity: 0;

        .nav {
          clear: both;
          white-space: nowrap;
          margin-left: 0;
          padding-bottom: 1px;
        }
      }
    }

    .nav.active {
      color: #000;
      font-weight: $font-weight-border;
      .sub-nav-container{
        .nav {
          font-weight: $font-weight-normal;
          color: #333;
        }
        .nav:hover {
          color: #000;
        }
      }
    }

    .sub-nav-container .nav:after {
      background: #000;
    }

    .nav:after {
      content: "";
      width: 0%;
      height: 0.1px;
      background: #000;
      display: block;
      margin: 0 auto;
      transition: all 0.2s;
    }

    .nav:hover {
      color: #000;

      .sub-nav-container {
        height: 80px;
        opacity: 1;
      }
    }

    .nav:hover::after {
      width: 100%;
    }
  }


}

.header.white-header {
  color: #ddd;

  .nav:hover {
    color: #f6f6f6;
  }

  .nav-container .nav.active {
    color: #fff;
    span{
      text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    }


    .sub-nav-container {
      .nav {
        font-weight: $font-weight-normal;
        color: #ddd;
      }

      .nav:hover {
        color: #f6f6f6;
      }
    }
  }

  .sub-nav-container .nav:after {
    background: #f6f6f6 !important;
  }

  .nav:after {
    background: #f6f6f6 !important;
  }
}
</style>