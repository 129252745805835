<template>
  <div class="job-container">
    <div class="job-title">
      <p>我们需要</p>
      <p>Job Opportunities</p>
    </div>
    <div class="job-content">
      <div class="job-card">
        <div class="left">
          <img style="width: 120px" src="assets/images/job_pic_1.png">
        </div>

        <div class="right">
          <div class="subtitle">室内设计师</div>
          <ul class="text">
            <li>国内外建筑、室内、环境艺术相关专业本科及以上学历;</li>
            <li>2-5 年建筑师事务所或室内设计公司的工作经验;有大型项目设计经验及施工经验优先;</li>
            <li>有良好的设计意识，拥有国际视野的审美和设计水准;</li>
            <li>有良好的职业精神和责任心;有较强的沟通能力和团队协作能力;有能力同时处理多个任务;</li>
            <li>能熟练使用 AutoCAD / Sketch Up / PS / AI / ID / Adobe 等相关的工作软件</li>
          </ul>
        </div>
      </div>
      <div class="job-card">
        <div class="left">
          <img style="height: 120px" src="assets/images/job_pic_2.png">
        </div>
        <div class="right">
          <div class="subtitle">室内设计师助理</div>
          <ul class="text">
            <li>国内外建筑、室内、环境艺术相关专业本科毕业；有良好的设计意识，拥有很好的审美和设计水准；</li>
            <li>有良好的职业精神和责任心；有较强的沟通能力和团队协作能力；能熟练使用 AutoCAD / Sketch Up / PS / AI / ID / Adobe 等相关的工作软件</li>
          </ul>
        </div>
      </div>
      <div class="job-card">
        <div class="left">
          <img style="width: 120px" src="assets/images/job_pic_3.png">
        </div>
        <div class="right">
          <div class="subtitle">实习生</div>
          <ul class="text">
            <li>建筑室内专业在读本科生（3 年级或以上）；热爱设计，拥有较强的审美和设计水准；</li>
            <li>能熟练使用 AutoCAD / Sketch Up / PS / AI / ID / Adobe 等相关的工作软件；保证 3 个月实习期。</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="foot">
      申请人请将不超过 20MB 的作品集 + 简历以 PDF 形式发送至 hr@gbspace.org，并在邮件标题处注明申请职位
    </div>
  </div>
</template>

<script>
export default {
  name: "page2"
}
</script>

<style scoped lang="scss">
@import "../../components/common/common.scss";

@media only screen and (max-width: 940px) {
  .job-container {
    padding: 0 20px;
  }
}

@media only screen and (min-width: 840px) and (max-width: 940px) {
  .text {
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 740px) and (max-width: 840px) {
  .text {
    font-size: 12px !important;
    line-height: 18px;
  }
}

@media only screen and (max-width: 740px) {
  .job-card {
    display: block !important;
  }
  .left {
    width: 100% !important;
  }
  .right {
    width: 100% !important;
    margin-right: 0;
    margin-top: 20px;
    font-size: 14px;
  }
}


.job-container {
  max-width: 940px;
  margin: 0 auto;

  .job-title {
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    padding: 40px 0 20px 0;
  }

  .job-content {
    width: 100%;

    .job-card {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      .left {
        height: 100%;
        margin-right: 20px;
      }

      .right {
        //width: 460px;
        width: 740px;
        height: 100%;
        text-align: left
      }
    }
  }
}


.foot {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding: 40px 0 60px 0;
  color: #888888
}

.subtitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  margin-bottom: 8px;
  color: #333333
}

.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #666666;

  div {
    margin-bottom: 4px;
  }
}

</style>
