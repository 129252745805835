<template>
  <div class="work-list-container">
    <div v-if="isPC" class="work-card-container" v-for="item in workListByNav" :key="item.id" @click="showWorkDetail(item)">
      <div class="work-card-poster" style="width: 100%;">
        <ImgPlus class="card-bg" :src="item.poster" :parentRatio="3 / 2"></ImgPlus>
      </div>
      <h1 class="work-card-title">{{ item.title }}</h1>
      <p class="work-card-desc">{{ item.subTitle || item.desc }}</p>
    </div>
    <div v-if="!isPC" class="work-card-container" v-for="item in list" :key="item.id" @click="showWorkDetail(item)">
      <div class="work-card-poster" style="width: 100%;">
        <ImgPlus class="card-bg" :src="item.poster" :parentRatio="3 / 2"></ImgPlus>
      </div>
      <h1 class="work-card-title">{{ item.title }}</h1>
      <p class="work-card-desc">{{ item.subTitle || item.desc }}</p>
    </div>
    <InfiniteLoading v-if="!isPC && !isAll" @infinite="load"/>
    <div style="display: none">{{ workListByNav }}</div>
  </div>
</template>

<script>
import InfiniteLoading from 'v3-infinite-loading';
import 'v3-infinite-loading/lib/style.css';
import ImgPlus from '@/components/common/ImgPlus';

export default {
  name: 'WorkList',
  props: ['workList', 'pageIndex', 'nav', 'isPC', 'pageSize'],
  components: {
    InfiniteLoading,
    ImgPlus
  },
  data() {
    return {
      loadIndex: 0,
      list: [],
      isAll: false,
      wordListByNavInfinite: [],
      clear: true
      // workCount:0
    };
  },
  computed: {
    workListByNav: function () {
      console.log('workListByNav');
      let {
        navGroup,
        nav,
        index
      } = this.$route.params;

      console.log({navGroup, nav, index});
      let list = [];
      if (navGroup === 'graphic') {
        this.workList.map((work) => {
          // //console.log(work);
          if (work.navGroup === 'graphicDesign') {
            list.push(work);
          }
        });
        this.$parent.workCount = list.length;
        if (!this.isPC) {
          this.wordListByNavInfinite = list;
          /*if (this.clear) {
            //console.warn('clear');
            this.loadIndex = 0;
            this.list = [];
            //console.warn(this.list, 'list');
            this.isAll = false;
            setTimeout(() => {
              this.loadNext();
              this.clear = false;
            }, 300);
          }*/
          return;
        }
        return list;
      } else {
        let searchNav = nav === '' ? 'all' : nav;
        let searchIndex = index === '' ? 1 : Number(index);
        for (let i = 0; i < this.workList.length; i++) {
          let work = this.workList[i];
          //console.warn('work.engNav', work.engNav);
          if (work.navGroup !== 'spaceDesign') continue;
          if (searchNav === 'all') list.push(work);
          else if (work.engNav === searchNav || (work.engNav instanceof Array && work.engNav.includes(searchNav))) list.push(work);
        }
        this.$parent.workCount = list.length;
        //console.log((searchIndex - 1) * this.pageSize, searchIndex * this.pageSize);
        //console.log('this.isPC', this.isPC);
        if (!this.isPC) {
          console.log(list)

          this.wordListByNavInfinite = list;
          return
          if (list.length === 0) {
            console.log("this.list.length === 0");
            return
          }
          /*if (this.clear && this.wordListByNavInfinite.length > 0) {
            console.warn('clear');
            this.loadIndex = 0;
            this.list = [];
            //console.warn(this.list, 'list');
            this.isAll = false;
            setTimeout(() => {
              this.loadNext();
              this.clear = false;
            }, 300);

          }*/
          return;
        }
        return list.splice((searchIndex - 1) * this.pageSize, searchIndex * this.pageSize);
      }

    }
  },
  watch:{
    wordListByNavInfinite(n,o){
      if (this.clear && this.wordListByNavInfinite.length > 0) {
        console.warn('clear');
        this.loadIndex = 0;
        this.list = [];
        //console.warn(this.list, 'list');
        this.isAll = false;
        setTimeout(() => {
          this.loadNext();
          this.clear = false;
        }, 300);

      }
    }
  },
  methods: {
    showWorkDetail(item) {
      //console.log(item);
      this.$router.push(`/detail/${item.id}/${item.title}`);
    },
    chooseNav() {
      //console.log('chooseNav');
      if (!this.isPc) {
        this.clear = true;
        this.loadIndex = 0;
        this.list = [];
        this.isAll = false;
      }
    },
    loadNext() {
      console.log(this.loadIndex, this.wordListByNavInfinite);
      if (this.loadIndex * 12 > this.wordListByNavInfinite.length) {
        console.log('complete======');
        this.isAll = true;
        return;
      }
      this.list.push(...this.wordListByNavInfinite.splice(this.loadIndex * 6, this.loadIndex * 6 + 6));
    },
    load($state) {
      console.log('loadIndex:', $state);
      this.loadNext();
      this.loadIndex++;
      $state.loaded();
      return
      setTimeout(() => {
        /*this.wordListByNavInfinite*/
        //console.log(this.loadIndex * 12, this.loadIndex * 12 + 12);
        //console.log(this.wordListByNavInfinite);
        //console.log(this.list);
        this.loadNext();
        this.loadIndex++;
        $state.loaded();
      }, this.loadIndex ? 800 : 100);

    }
  }
};
</script>

<style scoped lang="scss">
@media only screen and (max-width: 1360px) {
  .work-list-container {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1100px) {
  .work-list-container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

}

@media only screen and (max-width: 900px) {
  .work-list-container {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

}

@media only screen and (min-width: 800px) {
  .work-card-container {
    margin-right: 40px;
  }
  .work-card-container:nth-child(3n+0) {
    margin-right: 0 !important;
  }
  .work-card-container {
    width: calc(100% / 3 - 80px / 3);
  }
}

@media only screen and (min-width: 400px) and (max-width: 800px) {
  .work-card-container {
    margin-right: 20px;
  }
  .work-card-container:nth-child(2n+0) {
    margin-right: 0 !important;
  }
  .work-card-container {
    width: calc(100% / 2 - 20px / 2);
  }
}

@media only screen and (min-width: 600px) and (max-width: 800px) {
  .work-card-title {
    font-size: 3vw !important;
    line-height: 5vw !important;
  }

  .work-card-desc {
    font-size: 2.2vw !important;
    line-height: 2.8vw !important;
  }
}

@media only screen and (max-width: 400px) {
  .work-card-container {
    width: 100%;

    .work-card-title {
      margin-top: 16px !important;
    }
  }
}

.work-list-container {
  max-width: 1360px;
  margin: 0 auto;
  padding-bottom: 20px;
  padding-top: 20px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}

.work-card-container {

  float: left;
  text-align: left;
  margin-bottom: 40px;
  color: #333333;
  transition: color 0.5s;
  cursor: pointer;

  .work-card-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 5px;
    color: #333;
  }

  .work-card-poster {
    position: relative;
    padding-bottom: calc(100% * 2 / 3);
    overflow: hidden;

    .card-bg {
      transition: 0.5s;
      position: absolute;
    }
  }

  .work-card-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    height: 36px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    color: #999;
  }
}

.work-card-container:hover {
  color: black;

  .work-card-poster .p-container {
    transform: scale(1.1);
  }
}


</style>