<template>
  <div class="our-team-container">
    <div class="our-team-title">
      <p>我们的团队</p>
      <p>Our team</p>
    </div>
    <div class="our-team-line">
<!--      <div class="our-team-line-title">
        <p>室内设计 & 建筑设计</p>
        <p>Interior Design & Architecture Design</p>
      </div>-->
      <div class="our-team-person-box">
        <div class="our-team-person" v-for="item in persons">
          <img :src="item.pic" alt="">
          <div class="our-team-person-name">{{ item.name }}</div>
          <div class="our-team-person-desc">
            <p v-for="subdesc in item.desc">{{ subdesc }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="our-team-line">
<!--      <div class="our-team-line-title">
        <p>平面设计 & 交互设计</p>
        <p>Graphic Design & Interaction Design</p>
      </div>-->
      <div class="our-team-person-box">
        <div class="our-team-person" v-for="item in persons2">
          <img :src="item.pic" alt="">
          <div class="our-team-person-name">{{ item.name }}</div>
          <div class="our-team-person-desc">
            <p v-for="subdesc in item.desc">{{ subdesc }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurTeam",
  data() {
    return {
      persons: [
        {
          name: "龚亭如 / Angela Kung",
          desc: [
            "本科毕业于澳大利亚理工大学不动产经济专业，研究生毕业于澳大利亚昆士兰大学-信息系统管理专业。现担任澳斯丁酒店管理集团有限公司 董事，Wave.GB Design CEO，万义斋科技有限公司 董事长，布鲁安咖啡主理人。",
            /*"Graduated from :Queensland University of Technology (Bachelor of Property Economics)，University of Queensland (Master of Information Technology).She is currently the  Director of Austin Hotels Group Pty Ltd,CEO of Wave.GB Design, Chairman of Wan Yi Zhai Technology Co., Pty LtdDirector of Blue AN. Cafe."*/
          ],
          pic: "assets/images/our-team-gongtingru.webp"
        },
        {
          name: "高文毓 / Wenyu Gao",
          desc: [
            "本科毕业于中央美术学院建筑学院，硕士毕业于伦敦艺术大学室内与空间设计专业。现担任Wave.GB联合创始人、主创设计师。",
            // "Graduated from the Architecture School of Central Academy of Fine Arts with a bachelor’s degree, and University of the Arts London with a master’s degree in interior and spatial design. She is currently the co-founder and lead designer of Wave.GB."
          ],
          pic: "assets/images/our-team-gaowenyu.webp"
        },
        {
          name: "白杨 / Yang Bai",
          desc: [
            "硕士毕业于伦敦艺术大学室内与空间设计专业。现担任Wave.GB联合创始人、主创设计师。",
            // "Graduated from the University of the Arts London with a master’s degree in interior and spatial design. She is currently the co-founder and lead designer of Wave.GB."
          ],
          pic: "assets/images/our-team-baiyang.webp"
        },
        {
          name: "温双斌 / Shuangbin Wen",
          desc: [
            "硕士毕业于伦敦艺术大学切尔西艺术学院，北京国际设计周视觉设计总监,2014 - 2019北京国际设计周，设计管理,2015 滴滴出行视觉设计。现担任Wave.GB联合创始人、主创设计师。",
            // "Graduated from Chelsea College of Art of University of the Arts London; Director of Visual Design for Beijing Design Week; 2014-2019, Design Management; 2015, Visual Design for Didi Taxi. He is currently the co-founder and lead designer of Wave.GB."
          ],
          pic: "assets/images/our-team-wenshuangbin.webp"
        },
      ],
      persons2: [
        {
          name: "卢嘉兴 / Jiaxing Lu",
          desc: [
            "本科毕业于中央美术学院建筑学院，",
            "南加州建筑学院上海 Professor： Roland Snooks",
            "维也纳应用艺术大学 Professor： Zaha Hadid",
            "建筑学硕士 扎哈工作室 Patrik Schumacher",
            // "Graduated from Central Academy of Fine Arts with a bachelor’s degree",
            // "School of Architecture, Southern California Institute of Architecture, Shanghai; Professor: Roland Snooks",
            // "University of Applied Arts, Vienna; Professor: Zaha Hadid",
            // "Master of Architecture, Zaha Studio Patrik Schumacher"
          ],
          pic: "assets/images/our-team-lujiaxing.webp"
        },
        {
          name: "程雨阳 / Yuyang Cheng",
          desc: [
            "中央美院建筑学本科，UCL城市规划研究生",
            // "Bachelor of Architecture, Central Academy of Fine Arts",
            // "Master of Urban Planning, University College London"
          ],
          pic: "assets/images/our-team-chengyuyang.webp"
        },
        {
          name: "杨隆超 / Longchao Yang",
          desc: [
            "曾担任北京国际设计周设计师；Katana设计工作室资深设计师及侨福芳草地品牌视觉负责人；凤凰卫视数字科技资深品牌设计师及《清明上河图3.0》香港展视觉设计总监。",
            // "He served as the designer of Beijing Design Week; Senior designer of Katana Design Studio and visual director of Parkview Green; Senior brand designer of Phoenix Digital Technology and visual design director of “Riverside Scene at Qingming Festival 3.0” of Hong Kong exhibition."
          ],
          pic: "assets/images/our-team-yanglongchao.webp"
        },
        {
          name: "洪昀 / Yun Hong",
          desc: [
            "本科毕业于中央美术学院实验媒体工作室，硕士毕业于罗德岛设计学院数码媒体专业。从事新媒体展览展示与体验设计，用新媒介探索延展人体感知与突破空间界限的可能性。",
            // "Graduated from the CAFA Experimental Media Studio and received her master’s degree in Digital Media from the Rhode Island School of Design. She is engaged in new media exhibition and experience design, using new media to explore the possibility of extending human perception and breaking the boundaries of space."
          ],
          pic: "assets/images/our-team-hongyun.webp"
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../components/common/common.scss";

@media only screen and (min-width: 1100px) and (max-width: 1360px) {
  .our-team-container {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 1100px) {
  .our-team-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .our-team-person-box {
    flex-wrap: wrap;
  }
  .our-team-person {
    width: calc(50% - 5px) !important;
    margin-bottom: 20px;
  }
  .our-team-person:nth-child(even) {
    margin-right: 0 !important;
  }
}

@media only screen and (max-width: 900px) {
  .our-team-container {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}

@media only screen and (max-width: 450px) {
  .our-team-person-name {
    font-size: 16px !important;
  }
  .our-team-person-desc {
    font-size: 12px !important;
    line-height: 16px !important;
  }
}

.our-team-container {
  margin-top: 100px;

  .our-team-title {
    font-family: $font-family;
    font-style: $font-style;
    font-weight: $font-weight-border;
    font-size: $font-size-20;
    line-height: $line-height-20;
    text-align: left;
    padding-bottom: 20px;
    border-bottom: 1px solid #cccccc;
  }

  .our-team-line {
    padding-top: 20px;
    padding-bottom: 40px;
    text-align: left;
    //border-bottom: #aaa solid 1px;

    .our-team-person-box {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      .our-team-person {
        width: 330px;
        margin-right: 10px;

        img {
          width: 100%
        }

        .our-team-person-name {
          font-family: $font-family;
          font-style: $font-style;
          font-weight: $font-weight-border;
          font-size: $font-size-18;
          line-height: $line-height-18;
          margin-top: 12px;
          margin-bottom: 8px;
            color: #333333;
        }

        .our-team-person-desc {
          font-family: $font-family;
          font-style: $font-style;
          font-weight: $font-weight-normal;
          font-size: $font-size-14;
          line-height: $line-height-14;
          letter-spacing: 1px;
            color: #666666;
        }
      }

      .our-team-person:last-child {
        margin-right: 0;
      }
    }
  }

  .our-team-line:last-child {
    border: none;
  }
}
</style>